import { NotificationsSharp } from "@mui/icons-material";
import { Badge } from "@mui/material";
import React from "react";
const NotificationIcon = ({count}) => {
  return (
    <Badge
      badgeContent={count || 0}
      sx={{
        "& .MuiBadge-badge": {
          color: "white", // Text color
          backgroundColor: "#0a376a", // Background color
        },
      }}
    >
      <NotificationsSharp />
    </Badge>
  );
};

export default NotificationIcon;
