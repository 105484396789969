// import node module libraries
import React, { useState } from "react";
import Link from "next/link";
import { Card, Table, Dropdown, Image } from "react-bootstrap";
import { MoreVertical } from "react-feather";
import { Switch } from "@mui/material";
import Buttons from "../../../utils/buttons/Buttons";
import CustomModal from "../../../utils/modals/CustomModal";
import Paginations from "../../../utils/pagination/Paginations";
import FormEditClient from "./components/FormEditClient";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUserActionManager,
  updateUserActionManager,
} from "../../../../services/action/manager";
import DeleteConfirmation from "../../../utils/dialoge/DeleteConfirmation";
import FullScreenModal from "../../../utils/modals/FullScreenModal";
import PersonalCard from "../../../card/PersonalCard";
import OperatorSkeleton from "../../../skeleton/OperatorSkeleton";

const ClientListCompoent = ({
  getList,
  TeamsData,
  listTitle = "Client List",
}) => {
  const { isDeleteUser,isListLoading } = useSelector((state) => state.managerReducer);
  const {user}=useSelector((state)=>state.authenticationReducer);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const [operatorDetail, setOperatorDetail] = useState({
    modal: false,
    preview: false,
    edit: false,
    data: "",
  });

  const [deleteId, setDeleteId] = useState("");

  const onPagination = (currentPage) => {
    getList({ currentPage });
    setCurrentPage(currentPage);
  };

  const afterSave = () => {
    getList({ currentPage, isEmptylist: false });
    setOperatorDetail({});
  };

  const deleteFinal = () => {
    const userType=user?.userType?.toLowerCase();
    dispatch(
      deleteUserActionManager({
        userType,
        data: deleteId,
        cb: () => {
          setDeleteId("");
          getList({ currentPage, isEmptylist: false });
          setOperatorDetail({});
        },
      })
    );
  };

  const updateStatus = (data) => {
    const userType=user?.userType?.toLowerCase();
    dispatch(
      updateUserActionManager({
        userType,
        data: {
          _id: data?._id,
          status: data?.status === "ACTIVE" ? "NOT ACTIVE" : "ACTIVE",
        },
        cb: () => getList({ currentPage, isEmptylist: false }),
      })
    );
  };

  return (
    <Card className="h-100">
      <DeleteConfirmation
        open={deleteId}
        isDeleteLoading={isDeleteUser}
        onClose={() => setDeleteId("")}
        onConfirm={deleteFinal}
      />
      <CustomModal
        title={"Add Client"}
        bgContent="dark-bg"
        open={operatorDetail.modal}
        onClose={() => setOperatorDetail({})}
        content={() => (
          <FormEditClient data={operatorDetail?.data} close={afterSave} />
        )}
      />

      <FullScreenModal
        title={"Personal Details"}
        bgContent="dark-bg"
        size="xl"
        open={operatorDetail.preview}
        onClose={() => setOperatorDetail({})}
        content={() => (
          <PersonalCard
            onDeleteClick={(data) => setDeleteId(data)}
            onEditClick={(data) =>
              setOperatorDetail({
                edit: true,
                modal: true,
                preview: false,
                data,
              })
            }
            data={operatorDetail?.data}
            close={() => setOperatorDetail({})}
          />
        )}
      />

      <Card.Header className="bg-white py-4">
        <h4 className="mb-0">
          {listTitle}{" "}
          <Buttons
            onClick={() => setOperatorDetail({ modal: true, edit: false })}
            title={"Add Client"}
            icon="plus"
          />{" "}
        </h4>
      </Card.Header>
      <Table responsive className="text-nowrap">
        <thead className="table-light">
          <tr>
            <th>Name</th>
            <th>Role</th>
            <th>Phone</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {TeamsData?.data?.map((item, index) => {
            return (
              <tr key={index}>
                <td className="align-middle">
                  <div className="d-flex align-items-center">
                    <div className="width-37">
                      <Image
                        src={item.image}
                        alt=""
                        className="avatar-md avatar rounded-circle"
                      />
                    </div>
                    <div className="ms-3 lh-1">
                      <h5 className="mb-1 pointer"
                       onClick={() =>
                        setOperatorDetail({
                          data: item,
                          preview: true,
                          modal: false,
                        })
                      }
                      >{item.name}</h5>
                      <p className="mb-0">{item.email}</p>
                    </div>
                  </div>
                </td>
                <td className="align-middle">{item.userType}</td>
                <td className="align-middle">{item.number}</td>
                <td className="align-middle">
                  <Switch
                    inputProps={{ "aria-label": "Switch demo" }}
                    checked={item.status === "ACTIVE"}
                    onClick={() => updateStatus(item)}
                  />
                </td>
              </tr>
            );
          })}
           {isListLoading && (
            <tr>
              <td colSpan={5}>
                <OperatorSkeleton />
              </td>
            </tr>
          )}
          <tr>
            <td colSpan={5}>
              {TeamsData?.pagination && (
                <Paginations
                  onChange={onPagination}
                  pagination={TeamsData?.pagination}
                />
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    </Card>
  );
};

export default ClientListCompoent;
