import React, { useEffect } from 'react'
import ClientListCompoent from '../../components/manager/users/clients/ClientListCompoent';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsersAction } from '../../services/action/manager';
import DashboardLayout from '../../components/layouts/DashboardLayout';

const ClientList = () => {
  const dispatch = useDispatch();
  const {userList}=useSelector((state)=>state.managerReducer);
  const getList=({ currentPage,isEmptylist=true,keyword="" })=>{
    dispatch(getAllUsersAction({ data: { userType: "client",page:currentPage,isEmptylist,keyword } }));
  }
  useEffect(() => {
    getList({currentPage:1})
  }, []);
  return (
    <DashboardLayout onSearch={(e)=>getList({currentPage:1,isEmptylist:false,keyword:e})}>
    <ClientListCompoent getList={getList} TeamsData={userList}/>
    </DashboardLayout>
  )
}

export default ClientList