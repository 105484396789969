// FormEditOperator.js
import React, { useEffect } from "react";
import Buttons from "../../../../utils/buttons/Buttons";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  createUserActionManager,
  updateUserActionManager,
} from "../../../../../services/action/manager";
import CountryDropdown from "../../../../utils/country-dropdown/CountryDropdown";

const FormEditExpert = ({ data, close }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const { isSaveLoading } = useSelector((state) => state.managerReducer);
  const {user} =useSelector((state)=>state.authenticationReducer)
  const getWatch = watch();

  const dispatch = useDispatch();
  const submitData = (obj) => {
    const userType=user?.userType?.toLowerCase()
    obj.userType = "MANAGER";
    if (data) {
      obj._id = data?._id;
      dispatch(updateUserActionManager({userType, data: obj, cb: () => close() }));
    } else {
      dispatch(createUserActionManager({userType, data: obj, cb: () => close() }));
    }
  };

  useEffect(() => {
    setValue("userType", data?.userType);
    setValue("countryCode", data?.countryCode || "+91");
    setValue("name", data?.name);
    setValue("email", data?.email);
    setValue("number", data?.number);
  }, [data, setValue]);
  return (
    <div className="row custom-form">
      <div className="col-sm-6">
        <label className={`${errors?.email && "text-danger"}`}>
          Name<span className="text-danger">*</span>
        </label>
        <input
          {...register("name", {
            required: true,
          })}
          type="text"
          className={`form-control`}
        />
      </div>
      <div className="col-sm-6">
        <label className={`${errors?.email && "text-danger"}`}>
          Email<span className="text-danger">*</span>
        </label>
        <input
          {...register("email", {
            required: true,
          })}
          type="text"
          className="form-control"
        />
      </div>
      <div className="col-sm-6">
        <label className={`${errors?.number && "text-danger"}`}>
          Phone<span className="text-danger">*</span>
        </label>
        <CountryDropdown
          value={getWatch?.countryCode}
          disabled={data}
          onChange={(e) => setValue("countryCode", e?.countryCode)}
        />
        <input
          type="text"
          {...register("number", {
            required: true,
          })}
          style={{
            paddingLeft: getWatch?.countryCode?.length > 3 ? 59 : 40,
          }}
          className="form-control"
        />
      </div>

      <div className="col-sm-12 text-center pt-3">
        <Buttons
          isLoading={isSaveLoading}
          title={data ? "Update" : "Register Now"}
          onClick={handleSubmit(submitData)}
        />
      </div>
    </div>
  );
};

export default FormEditExpert;
