import api from "./apiInstance";

export const adminApi = {
  GET_ALL_USER_ACTION: ({userType="manager", data }) =>
    api.get(
      `/${userType}/users/${data?.userType}?page=${data?.page}&keyword=${data?.keyword}`
    ),
  CREATE_USER: ({ userType,data }) => api.post(`/${userType}/user`, data),
  DELETE_USER_MANAGER: ({ userType,data }) => api.delete(`/${userType}/user/${data}`),
  UPDATE_USER_MANAGER: ({userType, data }) => api.put(`/${userType}/user`, data),
  UPLOAD_IMAGE: ({ data }) => api.post("/upload-image", data),
  GET_CLIENT_LIST_OPTION_MANAGER: ({ userType }) =>
    api.get(`/${userType}/user-list-option`),
  GENERATE_LEAD_MANAGER: ({ data, userType }) =>
    api.post(`/${userType}/generate-lead`, data),
  GET_GENERATE_LEAD_ADMIN: ({ data, userType }) =>
    api.post(`/${userType}/get-leads`, data),
  CHANGE_CHAT_VISIBITY: ({ data, userType }) =>
    api.put(`/${userType}/change-chat-visibity`, data),
  UPDATE_LEAD_MANAGER: ({ data, userType }) =>
    api.put(`/${userType}/update-lead`, data),
  GET_CHAT: ({ userType, leadId }) =>
    api.get(`/${userType}/chat-list?leadId=${leadId}`),
  ORDER_DELIVERED_ACTION: ({ userType, data }) =>
    api.post(`/${userType}/order-delivered`, data),
  SEND_BALANCED_AMOUNT_TO_CLIENT:({ userType, data }) =>
    api.post(`/${userType}/send-balanced-amount-to-client`, data),
};
