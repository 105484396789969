import { combineReducers } from "redux";
import { authenticationReducer } from "./authentication";
import { commonReducer } from "./common";
import { managerReducer } from "./manager";

const appReducer = combineReducers({
  authenticationReducer,
  commonReducer,
  managerReducer,
});

export const rootReducer = (state, action) => {
  return appReducer(state, action);
};
