import React, { Fragment, useState } from "react";
import Icons from "../../components/utils/Icons";
import { Menu, MenuItem } from "@mui/material";
import UploadFile from "./UploadFile";
const ActionChatOpen = ({ setUserInputType }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelect = (inputType) => {
    setUserInputType(inputType);
    setAnchorEl(null);
  };

  return (
    <Fragment>
       <span onClick={handleClick} className="chat-action-btn">
        <Icons title={"three-dots"} color="#cccccc" size={32} />
      </span>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={() => onSelect("REQUEST_NAME")}>
          Get User Name
        </MenuItem>
        <MenuItem onClick={() => onSelect("REQUEST_NUMBER")}>
          Get Number
        </MenuItem>
        <MenuItem onClick={() => onSelect("REQUEST_EMAIL")}>Get Email</MenuItem>
        <MenuItem onClick={() => onSelect("REQUEST_MESSAGE")}>
          Get Message
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default ActionChatOpen;
