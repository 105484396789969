import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icons from "../../utils/Icons";
import { Menu, MenuItem } from "@mui/material";
import { changeChatVisiblityAction } from "../../../services/action/manager";
import socket from "../../socket/socket";
import SelectBox from "../../utils/select/SelectBox";
import {
  covertIntoLabelAndObject,
  userListOption,
} from "../../constants/commonConstant";
import Buttons from "../../utils/buttons/Buttons";

const EditableMessage = ({
  currentIndex,
  setInputMessage,
  setMessageId,
  currentData,
  leadId,
  setData,
}) => {
  const message = currentData?.message;
  const { user } = useSelector((state) => state.authenticationReducer);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const [isMasking, setMasking] = useState(false);
  const [specificUserType, setSpecificUserType] = useState([]);
  const isAdminOrSuperAdmin=user?.userType==="ADMIN" || user?.userType==="SUPER_ADMIN";

  const onDoubleClick = () => {
    if (user?.userType !== "MANAGER" && !isAdminOrSuperAdmin) {
      return false;
    }
    const checkEmoji = message?.includes("custom-emoji");
    const checkAudio = message?.includes("audio-message");
    const checkPaymentLink =
      message?.includes("PAYAMENT_LINK") || message?.includes("DOCUMENT_LINK");
    if (checkAudio) {
      return false;
    }

    if (checkPaymentLink) {
      const paymentLinkRegex = />([^<]+)</;
      let match = message.match(paymentLinkRegex);
      match = match ? match[1] : null;
      setInputMessage(match);
      setMessageId({
        currentIndex,
        messageId: currentData?._id,
        oldMessage: message,
      });
      return false;
    }

    if (checkEmoji) {
      const emojiRegex = />([^<]+)</;
      const match = message.match(emojiRegex);
      const emoji = match ? match[1] : null;
      setInputMessage(emoji);
      setMessageId({
        currentIndex,
        messageId: currentData?._id,
        oldMessage: message,
      });
    } else {
      setInputMessage(message);
      setMessageId({
        currentIndex,
        messageId: currentData?._id,
        oldMessage: message,
      });
    }
  };

  const callBackEdit = (isDeletable) => {
    if (!isDeletable) {
      return false;
    }
    setData((prevData) => {
      let filterChat = prevData?.filter(
        (item) => item?._id !== currentData?._id
      );
      return filterChat;
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const callBack = () => {
    socket.emit("refresh_lead_notification", { leadId });
    handleClose();
  };
  const changeVisibity = (newModule) => {
    const obj = {
      editedBy: user?._id,
      editTime: new Date(),
      onlyFor: newModule,
      isMasking: isMasking,
      messageId: currentData?._id,
      leadId,
    };
    const userType = user?.userType?.toLowerCase();
    dispatch(
      changeChatVisiblityAction({
        data: obj,
        userType,
        cb: () => {
          callBack();
          callBackEdit(newModule?.includes("DELETED"));
        },
      })
    );
  };

  useEffect(() => {
    if (currentData) {
      setMasking(currentData?.isMasking);
      let tempUser = covertIntoLabelAndObject(currentData?.onlyFor);
      setSpecificUserType(tempUser)
    }
  }, [currentData]);
  return (
    <Fragment>
      <div className="sender">
        <span
          className="message"
          onDoubleClick={onDoubleClick}
          dangerouslySetInnerHTML={{ __html: message }}
        ></span>

        {(user?.userType === "MANAGER" || isAdminOrSuperAdmin )&& (
          <span onClick={handleClick}>
            <Icons className={"pointer"} title={"down-arrow"} />
          </span>
        )}
      </div>
      <Menu
        id="basic-menu"
        className="action-dropdown-message"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{ zIndex: 10000 }}
      >
        <MenuItem
          style={{ color: "green" }}
          onClick={() => {
            onDoubleClick();
            handleClose();
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          style={{ color: "red" }}
          onClick={() => changeVisibity(["DELETED"])}
        >
          Delete
        </MenuItem>
        <MenuItem style={{ color: "red" }}>
          <label>Masked Message</label>
          <input
            type="checkbox"
            checked={isMasking}
            value={isMasking}
            onChange={() => setMasking(!isMasking)}
            className="masked-message-checkbox"
          />
        </MenuItem>
        <SelectBox
          className={"mx-2"}
          onChange={(data) => setSpecificUserType(data)}
          value={specificUserType}
          isMulti={true}
          options={userListOption}
        />
        <MenuItem>
          <Buttons
            onClick={() =>
              changeVisibity(specificUserType?.map((itm) => itm?.value))
            }
            style={{ width: "100%" }}
            title={"Update"}
          />
        </MenuItem>
        {/* <MenuItem
          style={{ color: "#0a376ac2" }}
          onClick={() => changeVisibity("ALL")}
        >
          ALL
        </MenuItem> */}
        {/* <MenuItem onClick={() => changeVisibity("CLIENT")}>
          Only for Client
        </MenuItem>
        <MenuItem onClick={() => changeVisibity("OPERATOR")}>
          Only for Operator
        </MenuItem>
        <MenuItem onClick={() => changeVisibity("EXPERT")}>
          Only for Expert
        </MenuItem> */}
      </Menu>
    </Fragment>
  );
};

export default EditableMessage;
