import { appActions } from "../../action";

export const uploadImageAction=payload=>({
  type: appActions.UPLOAD_IMAGE,
  payload,
})

export const uploadAudioAction=payload=>({
  type: appActions.UPLOAD_AUDIO,
  payload,
})

export const startChatAction=payload=>({
  type: appActions.START_CHAT,
  payload,
})

export const getChatListAction=payload=>({
  type: appActions.GET_CHAT,
  payload,
})


export const getDashboardAction=payload=>({
  type: appActions.GET_DASHBOARD,
  payload,
})
export const closeChatAction=payload=>({
  type: appActions.CLOSE_CHAT,
  payload,
})

export const getExpertListOptionAction=payload=>({
  type: appActions.GET_EXPERT_LIST_OPTION,
  payload,
})

export const getNotificationListAction=payload=>({
  type: appActions.GET_NOTIFICATION_LIST,
  payload,
})

export const updateNotificationAction=payload=>({
  type: appActions.UPDATE_NOTIFICATION,
  payload,
})

export const updateNotificationStatusAction=payload=>({
  type: appActions.UPDATE_NOTIFICATION_STATUS,
  payload,
})

export const generatePaymentLinkAction=payload=>({
  type: appActions.GENERATE_PAYMENT_LINK,
  payload,
})

export const getCountryListAction=payload=>({
  type: appActions.GET_COUNTRY_LIST,
  payload,
})

export const sendPaymentLinkOnWhatsappAction=payload=>({
  type: appActions.SENT_PAYMENT_LINK_WHATSAPP,
  payload,
})
export const getOperatorOrManagerListAction=payload=>({
  type: appActions.GET_OPERATOR_MANAGER_LIST_ACTION,
  payload,
})

export const requestForAssignLeadAction=payload=>({
  type: appActions.REQUEST_FOR_ASSIGN_LEAD,
  payload,
})

export const getAllLiveUserListAction=payload=>({
  type: appActions.GET_ALL_LIVE_USER_LIST,
  payload,
})

export const getOpenUserChatListAction=payload=>({
  type: appActions.GET_OPEN_USER_CHAT_LIST,
  payload,
})

export const getOpenUseNoticationAction=payload=>({
  type: appActions.GET_OPEN_NOTIFICATION,
  payload,
})

export const startOpenChatAction=payload=>({
  type: appActions.START_OPEN_CHAT,
  payload,
})
export const closeOpenChatAction=payload=>({
  type: appActions.CLOSE_OPEN_CHAT,
  payload,
})
export const setNotificationPermissionAction=payload=>({
  type: appActions.SET_NOTIFICATION_PERMISSION,
  payload,
})