import { Chip } from "@mui/material";
import React from "react";

const LiverUserFilterChips = ({onClick, color="primary",backgroundColor="green",label="",className="",isFilled=false}) => {
  return (
    <span onClick={onClick && onClick}>
    <Chip
    className={className}
    style={{cursor:"pointer"}}
      avatar={
        <div
          style={{
            height: "25px",
            width: "25px",
            backgroundColor: backgroundColor,
            borderRadius: "25px",
          }}
        ></div>
      }
      label={label}
      color={color}
      variant={isFilled?"":"outlined"}
    />
    </span>
  );
};

export default LiverUserFilterChips;
