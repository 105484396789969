import React, { Fragment } from "react";
import { currancyList, paymentTypeList } from "../../../utils/TeamsData";
import { useSelector } from "react-redux";
const FormPayment = ({
  page,
  isManager,
  data,
  errors,
  register,
  getWatch,
  STATUS,
  isAdminOrSuperAdmin
}) => {
  const { expertListOption } = useSelector((state) => state.commonReducer);
  return (
    <>
      <div className="col-sm-6">
        <label className={errors?.clientDeadline && "text-danger"}>
          Client Deadline<span className="text-danger">*</span>
        </label>
        <input
          {...register("clientDeadline", {
            required: true,
          })}
          disabled={(!isManager && data) && !isAdminOrSuperAdmin}
          min={new Date().toISOString().slice(0, 16)}
          type="datetime-local"
          className="form-control"
        />
      </div>
      {getWatch[STATUS] !== "NEW" && page === "order" && (
        <div className="col-sm-6">
          <label className={errors?.expertDeadline && "text-danger"}>
            Expert Deadline
            {getWatch?.expert && <span className="text-danger">*</span>}
          </label>
          <input
            {...register("expertDeadline", {
              required: !!getWatch?.expert,
            })}
            disabled={(!isManager && data?.expertDeadline) && !isAdminOrSuperAdmin }
            min={new Date().toISOString().slice(0, 16)}
            type="datetime-local"
            max={
              getWatch?.clientDeadline &&
              new Date(getWatch?.clientDeadline).toISOString().slice(0, 16)
            }
            className="form-control"
          />
        </div>
      )}
      {(page === "order" || getWatch[STATUS] === "PAID") && (
        <>
          <div className="col-sm-6">
            <label className={errors?.currancy && "text-danger"}>
              Currency<span className="text-danger">*</span>
            </label>
            <select
              className="form-control"
              {...register("currancy", {
                required: true,
              })}
              disabled={data?.currancy && !isManager}
            >
              {currancyList.map((item, index) => (
                <option key={index} value={item?.value}>
                  {item?.label}
                </option>
              ))}
            </select>
          </div>
          <div className="col-sm-6">
            <label className={errors?.paymentType && "text-danger"}>
              Payment Type<span className="text-danger">*</span>
            </label>
            <select
              className="form-control"
              {...register("paymentType", {
                required: true,
              })}
              disabled={data?.paymentType && !isManager && !isAdminOrSuperAdmin}
            >
              {paymentTypeList.map((item, index) => (
                <option key={index} value={item?.value}>
                  {item?.label}
                </option>
              ))}
            </select>
          </div>

          <div className="col-sm-6">
            <label className={errors?.totalPayment && "text-danger"}>
              Total Payment<span className="text-danger">*</span>
            </label>
            <input
              {...register("totalPayment", {
                required: true,
              })}
              disabled={data?.totalPayment && !isManager && !isAdminOrSuperAdmin}
              type="number"
              className="form-control"
            />
          </div>
          <div className="col-sm-6">
            <label>Payment Received</label>
            <input
              {...register("paymentRecieved", {
                required: true,
              })}
              disabled={data?.paymentRecieved && !isManager && !isAdminOrSuperAdmin}
              type="number"
              className="form-control"
            />
          </div>
          {page === "order" && (
            <Fragment>
              <div className="col-sm-6">
                <label className={errors?.expert && "text-danger"}>Expert {getWatch?.orderStatus!=="OPEN" &&<span className="text-danger">*</span>}</label>
                <select
                  className="form-control"
                  {...register("expert",{
                    required:getWatch?.orderStatus!=="OPEN"
                  })}
                  disabled={data?.expert && !isManager && !isAdminOrSuperAdmin}
                >
                  <option value={""}></option>
                  {expertListOption?.map((item, index) => (
                    <option
                      selected={getWatch?.expert === item?.value}
                      key={index}
                      value={item?.value}
                    >
                      {item?.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-6">
                <label className={errors?.expertAmount && "text-danger"}>Expert Amount{getWatch?.orderStatus!=="OPEN" &&<span className="text-danger">*</span>}</label>
                <input
                  {...register("expertAmount", {
                    required:getWatch?.orderStatus!=="OPEN",
                  })}
                  type="text"
                  disabled={data?.expertAmount && !isManager && !isAdminOrSuperAdmin}
                  className="form-control"
                />
              </div>
            </Fragment>
          )}
        </>
      )}
    </>
  );
};

export default FormPayment;
