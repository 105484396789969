import { Modal } from "react-bootstrap";
import React, { useEffect } from "react";

const CustomModal = ({headerShown=true, zIndex,bgContent,title,content, open, onClose, size = "lg" }) => {
  useEffect(()=>{
    const element=document.getElementsByClassName("delete-tabindex");
    if(element[0]){
    element[0].removeAttribute("tabindex")
    }
  })
  return (
    <Modal
      size={size}
      show={open}
      onHide={onClose}
      aria-labelledby="example-modal-sizes-title-lg"
      className={"delete-tabindex "+bgContent}
      style={{zIndex}}
    >
      {headerShown && <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {title}
        </Modal.Title>
      </Modal.Header>}
      <Modal.Body>{content()}</Modal.Body>
    </Modal>
  );
};

export default CustomModal;
