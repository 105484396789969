import { useState, useRef, useEffect } from "react";
import Icons from "../../utils/Icons";
import { useDispatch, useSelector } from "react-redux";
import { uploadAudioAction } from "../../../services/action/common";

const VoiceButton = ({ sendMessage, setRecordingStart }) => {
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const { isAudioLoading } = useSelector((state) => state.commonReducer);
  const audioChunksRef = useRef([]); // Use a ref to keep track of audio chunks
  const dispatch = useDispatch();

  const callBackImage = (res) => {
    const data = `<audio class="audio-message" controls>
  <source src=${res?.url} type="audio/mpeg">
  Your browser does not support the audio tag.
</audio>`;
    sendMessage(data);
  };

  useEffect(() => {
    return () => {
      if (mediaRecorder) {
        mediaRecorder.stop();
        mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [mediaRecorder]);

  const startRecording = () => {
    audioChunksRef.current = []; // Clear previous chunks

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const recorder = new MediaRecorder(stream);

        recorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            audioChunksRef.current.push(event.data); // Update ref with new chunk
          }
        };

        recorder.onstop = () => {
          const audioChunks = audioChunksRef.current;

          if (audioChunks.length > 0) {
            const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
            const formData = new FormData();
            formData.append("file", audioBlob, "recording.wav");
            dispatch(uploadAudioAction({ data: formData, cb: callBackImage }));
          } else {
            console.error("No audio data captured.");
          }
        };

        recorder.start();
        setMediaRecorder(recorder);
        setRecordingStart(true);
      })
      .catch((err) => {
        console.error("Error accessing media devices.", err);
        alert("Microphone access is required to record audio.");
      });
  };

  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state === "recording") {
      mediaRecorder.stop();
      setRecordingStart(false);
    }
  };

  return (
    <span
      onMouseDown={startRecording}
      onMouseUp={stopRecording}
      onTouchStart={startRecording}
      onTouchEnd={stopRecording}
    >
      <Icons
        title={isAudioLoading ? "loader" : "mic"}
        className="pointer mic-icon"
        color="#fff"
        size={20}
      />
    </span>
  );
};

export default VoiceButton;
