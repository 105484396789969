import React, { useEffect } from "react";
import Icons from "../../components/utils/Icons";
import { useDispatch, useSelector } from "react-redux";
import { getUserAction, updatePasswordAction } from "../../services/action/authentication";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

const UpdatePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLogin, user } = useSelector((state) => state.authenticationReducer);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const { resetId, userId } = useParams();

  useEffect(() => {
    console.log("resetId", resetId);
  }, [resetId]);

  const checkLogin = (tempUser) => {
    if (tempUser && tempUser?.userType === "MANAGER") {
      navigate("/manager/dashboard");
    } else if (tempUser && tempUser?.userType === "OPERATOR") {
      navigate("/operator/dashboard");
    } else if (tempUser && tempUser?.userType === "CLIENT") {
      navigate("/client/dashboard");
    } else if (tempUser && tempUser?.userType === "EXPERT") {
      navigate("/expert/dashboard");
    }
  };

  useEffect(() => {
    const tokens = localStorage.getItem("token");
    if (tokens) {
      dispatch(getUserAction());
    }
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      checkLogin(user);
    }
  }, [user, navigate]);

  const updatePassword = (data) => {
    const callBack = () => {
      navigate("/");
    };
    const errorCB = () => {
      navigate("/404");
    };
    const obj = {
      password: data?.password,
      userId,
      resetPasswordToken: resetId,
    };
    dispatch(updatePasswordAction({ data: obj, cb: callBack, errorCb: errorCB }));
    // Here you would typically send the data to your API for updating the password.
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-purple-400 via-pink-500 to-red-500">
      <div className="max-w-lg w-full space-y-8 m-4 p-10 bg-white rounded-xl shadow-lg z-10">
        <div className="text-center">
          <img src="/images/logo.png" alt="Logo" />
        </div>
        <form
          className="mt-8 space-y-6"
          onSubmit={handleSubmit(updatePassword)}
        >
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="relative">
            <label className="text-sm font-bold text-gray-700 tracking-wide">
              Password
            </label>
            <input
              className="w-full text-base py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
              type="password"
              placeholder="Enter your password"
              {...register("password", {
                required: "Password is required",
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters long",
                },
              })}
            />
            {errors.password && (
              <span className="text-red-500 text-xs">
                {errors.password.message}
              </span>
            )}
          </div>
          <div className="mt-8 relative">
            <label className="text-sm font-bold text-gray-700 tracking-wide">
              Confirm Password
            </label>
            <input
              className="w-full text-base py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
              type="password"
              placeholder="Confirm your password"
              {...register("confirmPassword", {
                required: "Please confirm your password",
                validate: (value) =>
                  value === watch("password") || "Passwords do not match",
              })}
            />
            {errors.confirmPassword && (
              <span className="text-red-500 text-xs">
                {errors.confirmPassword.message}
              </span>
            )}
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center"></div>
            <div className="text-sm">
              <Link
                to="/reset-password"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Resend Email Link?
              </Link>
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="w-full flex justify-center btn-primary text-gray-100 p-4 rounded-full tracking-wide font-semibold shadow-lg cursor-pointer transition ease-in duration-500"
            >
              {isLogin ? <Icons title="loader" /> : "Update Password"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdatePassword;
