import { appActions } from "../../action";
const initialState = {
  user: null,
};

export const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${appActions.LOGIN}_START`:
      return { ...state, isLogin: true };
    case `${appActions.LOGIN}_SUCCESS`:
      localStorage.setItem("token", action?.payload?.token);
      return { ...state, isLogin: false };
    case `${appActions.LOGIN}_FAIL`:
      return { ...state, isLogin: false };

    case `${appActions.RESET_PASSWORD}_START`:
      return { ...state, isReset: true };
    case `${appActions.RESET_PASSWORD}_SUCCESS`:
      return { ...state, isReset: false };
    case `${appActions.RESET_PASSWORD}_FAIL`:
      return { ...state, isReset: false };

    case `${appActions.GET_USER}_SUCCESS`:
      return { ...state, isLogin: false, user: action?.payload?.user };

    case appActions.LOGOUT:
      localStorage.clear();
      return {};
    default:
      return { ...state };
  }
};
