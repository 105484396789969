import React, { useEffect } from "react";
import OrderListComponent from "../../components/manager/leads/OrderListComponent";
import DashboardLayout from "../../components/layouts/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { getLeadsAction } from "../../services/action/manager";

const OpenLeadExpert = () => {
  const dispatch = useDispatch();
  const { leads } = useSelector((state) => state.managerReducer);

  const getList = ({ currentPage,isEmptylist=true,search="",filter=["OPEN"],sortConfig }) => {
    dispatch(
      getLeadsAction({ 
        userType:"expert",
        data: {allOpen:"OPEN",sortConfig, search, page: currentPage,isEmptylist,status:filter} })
    );
  };
  useEffect(() => {
    getList({ currentPage: 1 });
  }, []);
  return (
    <DashboardLayout onSearch={(value)=>getList({search:value})}>
      <OrderListComponent isOpenLeadOnly={true} getList={getList} TeamsData={leads} listTitle={"Open Lead"} />
    </DashboardLayout>
  );
};

export default OpenLeadExpert;
