// OperatorSkeleton.js
import React from 'react';

const OperatorSkeleton = ({ count = 9 }) => {
  return (
    <div className="w-full">
      <div className="bg-gray-50 rounded-lg overflow-hidden">
        {[...Array(count)].map((_, index) => (
          <div key={index} className="flex flex-col sm:grid sm:grid-cols-5 gap-2 sm:gap-4 px-4 py-3 border-b border-gray-200 items-start sm:items-center">
            <div className="flex items-center space-x-3 w-full sm:w-auto">
              <div className="w-10 h-10 bg-gray-200 rounded-full flex-shrink-0"></div>
              <div className="space-y-1 flex-grow">
                <div className="h-4 bg-gray-200 rounded w-32"></div>
                <div className="h-3 bg-gray-200 rounded w-40"></div>
              </div>
            </div>
            <div className="h-4 bg-gray-200 rounded w-20"></div>
            <div className="h-4 bg-gray-200 rounded w-24"></div>
            <div className="h-4 bg-gray-200 rounded w-24"></div>
            <div className="h-6 bg-gray-200 rounded w-12"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OperatorSkeleton;