import React, { Fragment, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  getOpenUserChatListAction,
} from "../../services/action/common";
import socket from "../../components/socket/socket";

const OpenNotification = ({setOpenChatBox, isOpenChat, userId }) => {
  const dispatch = useDispatch();
  const audioRef = useRef();

  const refreshBuutton = () => {
    dispatch(
      getOpenUserChatListAction({
        data: { userId, isOpenUser: true },
      })
    );
  };

  useEffect(() => {
    socket.on("send_notification_to_user", (_) => {
      setOpenChatBox(true)
      if (audioRef.current) {
        const playPromise = audioRef.current.play();
        if (playPromise !== undefined) {
          playPromise.catch((error) => {
            console.log("Playback prevented:", error);
          });
        }
      }
    });
    return () => {
      socket.off("send_notification_to_user");
    };
  }, []);


  return (
    <Fragment>
      <audio
        ref={audioRef}
        src={isOpenChat ? "/assets/seen.wav" : "/assets/ringtone.wav"}
      />
      <span onClick={refreshBuutton} className="notification-badge">
        {/* {allNotication?.length || 0} */}
      </span>
    </Fragment>
  );
};

export default OpenNotification;
