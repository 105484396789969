import { Fragment, useState } from "react";
import Icons from "../../utils/Icons";
import { useSelector } from "react-redux";
import { MenuItem, Menu } from "@mui/material";
import DeliveryBox from "./expert/DeliveryBox";
import MarkQuery from "./expert/MarkQuery";
import DeliveryConfirmation from "./operator/DeliveryConfirmation";

const AddMoreOption = ({ sendMessage }) => {
  const { user } = useSelector((state) => state.authenticationReducer);
  const [anchorEl, setAnchorEl] = useState(null);
  const [deliveryBox, setDeliveryBox] = useState({
    data: "",
    modal: false,
  });
  const [markQuery, setMarkQuery] = useState({
    data: "",
    modal: false,
  });
  const isAdminOrSuperAdmin=user?.userType==="ADMIN" || user?.userType==="SUPER_ADMIN";

  const [deliveryConfirmation, setDeliveryConfirmation] = useState({
    data: "",
    modal: false,
  });
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (user?.userType === "OPERATOR" || user?.userType === "MANAGER" || user?.userType === "EXPERT" || isAdminOrSuperAdmin) && (
    <Fragment>
      <DeliveryBox
        deliveryBox={deliveryBox}
        setDeliveryBox={setDeliveryBox}
        sendMessage={sendMessage}
      />
      <MarkQuery
        sendMessage={sendMessage}
        markQuery={markQuery}
        setMarkQuery={setMarkQuery}
      />
      <DeliveryConfirmation
        deliveryConfirmation={deliveryConfirmation}
        setDeliveryConfirmation={setDeliveryConfirmation}
        sendMessage={sendMessage}
      />
      <Icons
        title={"three-dots"}
        className="pointer  mt-1"
        color="#fff"
        size={20}
        onClick={handleClick}
      />
      {anchorEl && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={{ zIndex: 10001 }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {user?.userType === "EXPERT" && (
            <Fragment>
              <MenuItem
                onClick={() => {
                  setDeliveryBox({ modal: true });
                  setAnchorEl(null);
                }}
              >
                Upload Solution
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setMarkQuery({ modal: true });
                  setAnchorEl(null);
                }}
              >
                Mark Query
              </MenuItem>
            </Fragment>
          )}

          {(user?.userType === "OPERATOR" ||user?.userType === "MANAGER" || isAdminOrSuperAdmin)   && (
            <Fragment>
              <MenuItem
                onClick={() =>
                  setDeliveryConfirmation({ modal: setDeliveryConfirmation })
                }
              >
                Deliver
              </MenuItem>
            </Fragment>
          )}
        </Menu>
      )}
    </Fragment>
  );
};
export default AddMoreOption;
