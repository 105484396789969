// FormEditOperator.js
import React, { useEffect } from "react";
import Buttons from "../../../../utils/buttons/Buttons";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  createUserActionManager,
  updateUserActionManager,
} from "../../../../../services/action/manager";
import CountryDropdown from "../../../../utils/country-dropdown/CountryDropdown";

const FormEditExpert = ({ data, close }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const { isSaveLoading } = useSelector((state) => state.managerReducer);
  const {user}=useSelector((state)=>state.authenticationReducer);
  const getWatch = watch();

  const dispatch = useDispatch();
  const submitData = (obj) => {
    const userType=user?.userType?.toLowerCase();
    obj.userType = "EXPERT";
    if (data) {
      obj._id = data?._id;
      dispatch(updateUserActionManager({ userType,data: obj, cb: () => close() }));
    } else {
      dispatch(createUserActionManager({ userType,data: obj, cb: () => close() }));
    }
  };

  useEffect(() => {
    setValue("userType", data?.userType);
    setValue("countryCode", data?.countryCode || "+91");
    setValue("name", data?.name);
    setValue("email", data?.email);
    setValue("number", data?.number);
    setValue("expertType", data?.expertType);
    setValue("primaryExpertise", data?.primaryExpertise);
    setValue("secondaryExpertise", data?.secondaryExpertise);
    setValue("ppwRate", data?.ppwRate);
    setValue("panCardNo", data?.panCardNo);
    setValue("bankDetails.bankName", data?.bankDetails.bankName);
    setValue("bankDetails.accountNo", data?.bankDetails.accountNo);
    setValue("bankDetails.confirmAccountNo", data?.bankDetails.accountNo);
    setValue("bankDetails.ifsc", data?.bankDetails.ifsc);
  }, [data, setValue]);
  return (
    <div className="row custom-form">
      <div className="col-sm-6">
        <label className={`${errors?.email && "text-danger"}`}>
          Name<span className="text-danger">*</span>
        </label>
        <input
          {...register("name", {
            required: true,
          })}
          type="text"
          className={`form-control`}
        />
      </div>
      <div className="col-sm-6">
        <label className={`${errors?.email && "text-danger"}`}>
          Email<span className="text-danger">*</span>
        </label>
        <input
          {...register("email", {
            required: true,
          })}
          type="text"
          className="form-control"
        />
      </div>
      <div className="col-sm-6">
        <label className={`${errors?.number && "text-danger"}`}>
          Phone<span className="text-danger">*</span>
        </label>
        <CountryDropdown
          value={getWatch?.countryCode}
          disabled={data}
          onChange={(e) => setValue("countryCode", e?.countryCode)}
        />
        <input
          type="text"
          {...register("number", {
            required: true,
          })}
          style={{
            paddingLeft: getWatch?.countryCode?.length > 3 ? 59 : 40,
          }}
          className="form-control"
        />
      </div>
      <div className="col-sm-6">
        <label className={`${errors?.expertType && "text-danger"}`}>
          Expert Type<span className="text-danger">*</span>
        </label>
        <select
          className="form-control"
          {...register("expertType", {
            required: true,
          })}
        >
          <option value={""}></option>
          <option value={"INTERNAL"}>Internal</option>
          <option value={"EXTERNAL"}>External</option>
        </select>
      </div>
      <div className="col-sm-6">
        <label className={`${errors?.primaryExpertise && "text-danger"}`}>
          Primary Expertise<span className="text-danger">*</span>
        </label>
        <input
          type="text"
          {...register("primaryExpertise", { required: true })}
          className="form-control"
        />
      </div>
      <div className="col-sm-6">
        <label>Secondary Expertise</label>
        <input
          {...register("secondaryExpertise")}
          type="text"
          className="form-control"
        />
      </div>
      <div className="col-sm-6">
        <label className={`${errors?.ppwRate && "text-danger"}`}>
          PPW Rate<span className="text-danger">*</span>
        </label>
        <input
          type="text"
          {...register("ppwRate", { required: true })}
          className="form-control"
        />
      </div>
      <div className="col-sm-6">
        <label>Pancard No</label>
        <input
          {...register("panCardNo")}
          type="text"
          className="form-control"
        />
      </div>
      <div className="col-sm-6">
        <label>Bank Name</label>
        <input
          type="text"
          {...register("bankDetails.bankName")}
          className="form-control"
        />
      </div>
      <div className="col-sm-6">
        <label>IFSC Code</label>
        <input
          type="text"
          {...register("bankDetails.ifsc")}
          className="form-control"
        />
      </div>
      <div className="col-sm-6">
        <label>Bank Account No</label>
        <input
          type="password"
          {...register("bankDetails.accountNo")}
          className="form-control"
        />
        {errors?.bankDetails?.confirmAccountNo && (
          <p className="text-danger">{"Account numbers do not match"}</p>
        )}
      </div>
      <div className="col-sm-6">
        <label className={`${errors?.bankDetails?.confirmAccountNo}`}>
          Confirm Account No
          {getWatch?.bankDetails?.accountNo && (
            <span className="text-danger">*</span>
          )}
        </label>
        <input
          type="number"
          {...register("bankDetails.confirmAccountNo", {
            required: getWatch?.bankDetails?.accountNo,
            validate: (value) =>
              !value ||
              value === getWatch?.bankDetails?.accountNo ||
              "Account numbers do not match",
          })}
          className="form-control"
        />
      </div>

      <div className="col-sm-12 text-center pt-3">
        <Buttons
          isLoading={isSaveLoading}
          title={data ? "Update" : "Register Now"}
          onClick={handleSubmit(submitData)}
        />
      </div>
    </div>
  );
};

export default FormEditExpert;
