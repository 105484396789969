import React from "react";
import { useSelector } from "react-redux";
import Icons from "../../../utils/Icons";
import ImageUrlList from "./ImageUrlList";

const FormFileUpload = ({
  uploadImage,
  imageRef,
  isScreenShot,
  setValue,
  getWatch,
  documentRef,
  data,
  STATUS,
  errors
}) => {
  const { isImageLoading } = useSelector((state) => state.commonReducer);
  const { user } = useSelector((state) => state.authenticationReducer);
  const isManager = user?.userType === "MANAGER";
  const isClient = user?.userType === "CLIENT";
  const disable = data?.leadStatus === "PAID";
  return (
    <div className="row">
      <div className={`col-sm-${isClient ?"12":"6"}`}>
        <input
          type="file"
          onChange={uploadImage}
          ref={imageRef}
          className="display-none"
          multiple={true}
        />
        <div
          className="input-file-drop"
          onClick={() => {
            if (!disable || isManager) {
              imageRef.current?.click();
            }
          }}
        >
          <div className="parent-upload">
            <span>Upload Document</span>
            <span>
              {isImageLoading && !isScreenShot ? (
                <Icons title={"loader"} color="#0a376a" />
              ) : (
                <Icons title={"upload-file"} className={"upload-file"} />
              )}
            </span>
          </div>
        </div>
        <ImageUrlList
          onDelete={(image) => {
            let filterDocument = getWatch?.document?.filter(
              (item) => item !== image
            );
            setValue("document", filterDocument);
          }}
          items={getWatch?.document}
        />
      </div>
      {!isClient && <div className="col-sm-6">
        <input
          type="file"
          multiple={true}
          onChange={(e) => uploadImage(e, "screenshots")}
          ref={documentRef}
          className="display-none"
        />
        <div
          className="input-file-drop"
          onClick={() => {
            if (!disable || isManager) {
              documentRef.current?.click();
            }
          }}
        >
          <div className="parent-upload">
            <span className={errors[STATUS]&&"text-danger" }>Payment Screenshot{getWatch[STATUS]==="PAID" &&<b className="text-danger">*</b>}</span>

            <span>
              {isImageLoading && isScreenShot ? (
                <Icons title={"loader"} color="#0a376a" />
              ) : (
                <Icons title={"upload-file"} className={"upload-file"} />
              )}
            </span>
          </div>
        </div>
        <ImageUrlList
          onDelete={(image) => {
            if(disable){
              return false;
            }
            let filterDocument = getWatch?.screenshots?.filter(
              (item) => item !== image
            );
            setValue("screenshots", filterDocument);
          }}
          items={getWatch?.screenshots || []}
        />
      </div>}
    </div>
  );
};

export default FormFileUpload;
