import { Fragment, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import "simplebar/dist/simplebar.min.css";
import useMounted from "../../hooks/useMounted";
import QuickMenuDesktop from "./notification/QuickMenuDesktop";
import QuickMenuMobile from "./notification/QuickMenuMobile";
import socket from "../socket/socket";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotificationListAction,
  getOperatorOrManagerListAction,
  setNotificationPermissionAction,
  updateNotificationAction,
} from "../../services/action/common";
import AllowNotificationConfirmation from "../utils/dialoge/AllowNotificationConfirmation";

const QuickMenu = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authenticationReducer);
  const audioRef = useRef();
  const [audioAllowed, setAudioAllowed] = useState(false); // Track if audio is allowed
  const [showPermissionPrompt, setShowPermissionPrompt] = useState(true); // Show permission prompt
  const {isShowNotificationPopup}=useSelector((state)=>state.commonReducer);

  // Only register the notification action when the user is present
  useEffect(() => {
    if (user?.userType) {
      let userType = user?.userType?.toLowerCase();
      dispatch(getNotificationListAction({ userType }));
    }
  }, [user, dispatch]);

  const hasMounted = useMounted();

  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const enableAudioPlayback = () => {
    if (audioRef.current) {
      dispatch(setNotificationPermissionAction({isShown:true}))
      setAudioAllowed(true); 
      setShowPermissionPrompt(false);
    }
  };

  // Socket connection handling and audio playback
  useEffect(() => {
    if (!audioAllowed) return; // Only add listener if audio is allowed

    const handleNotification = ({
      orderId,
      notificationId,
      subject,
      messageId,
      senderId,
      sendTime,
      leadId,
      senderName,
      message,
      messageType,
      userId,
    }) => {
      dispatch(
        updateNotificationAction({
          orderId,
          _id: notificationId,
          subject,
          messageId,
          senderId,
          sendTime,
          leadId,
          senderName,
          message,
          messageType,
          userId,
        })
      );

      // Play audio when notification is received
      if (audioRef.current) {
        const playPromise = audioRef.current.play();
        if (playPromise !== undefined) {
          playPromise.catch((error) => {
            console.log("Playback prevented:", error);
          });
        }
      }
    };

    // Register socket event only once
    socket.on("send_notification", handleNotification);

    return () => {
      socket.off("send_notification", handleNotification); // Cleanup listener on unmount
    };
  }, [dispatch, audioAllowed]);

  // Fetch operator or manager list based on user type
  useEffect(() => {
    if (user?.userType === "MANAGER") {
      dispatch(getOperatorOrManagerListAction({ userType: user?.userType?.toLowerCase() }));
    }
  }, [user]);

  return (
    <Fragment>
      <audio ref={audioRef} src="/assets/ringtone.wav" />
      <AllowNotificationConfirmation
        handleClose={() => setShowPermissionPrompt(false)}
        onAllow={enableAudioPlayback}
        open={showPermissionPrompt && !isShowNotificationPopup}
      />

      {hasMounted && isDesktop ? <QuickMenuDesktop /> : <QuickMenuMobile />}
    </Fragment>
  );
};

export default QuickMenu;
