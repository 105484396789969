import { Card, Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import socket from "../socket/socket";
import {
  returnDateWithTime,
  truncateString,
} from "../utils/functions/functions";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllLiveUserListAction,
  startOpenChatAction,
} from "../../services/action/common";
import Buttons from "../utils/buttons/Buttons";
import Icons from "../utils/Icons";
import { Tooltip } from "@mui/material";
import LiverUserFilterChips from "./components/LiverUserFilterChips";
import LiveUserSelectWebsite from "./components/LiveUserSelectWebsite";
import Paginations from "../utils/pagination/Paginations";
import DateRangeFilter from "./components/DateRangeFilter";
const LiveUserTrack = () => {
  const [currentUserList, setCurrentUserList] = useState({
    paginations: "",
    data: [],
  });
  const { user } = useSelector((state) => state.authenticationReducer);
  const [filter, setFilter] = useState({
    searchQuery: "",
    website: "all website",
    isOnline: undefined,
    onlyTodayUser: undefined,
    from: undefined,
    to: undefined,
  });
  const { allLiveUser } = useSelector((state) => state.commonReducer);
  const dispatch = useDispatch();
  const [existUser, setExistUser] = useState(false);

  const getAllLiveUser = ({
    page = 1,
    tempWebsite = filter.website,
    tempIsOnline = filter?.isOnline,
    tempSearchQuery = filter.searchQuery,
    tempOnlyTodayUser = filter.onlyTodayUser,
    from = filter.from,
    to = filter.to,
  }) => {
    let userType = user?.userType?.toLowerCase();
    const tempFilter = {
      website: tempWebsite,
      isOnline: tempIsOnline,
      searchQuery: tempSearchQuery,
      onlyTodayUser: tempOnlyTodayUser,
      from,
      to,
      page,
    };
    dispatch(getAllLiveUserListAction({ userType, filter: tempFilter }));
  };
  useEffect(() => {
    if (existUser) {
      let existUserIndex = currentUserList?.data?.findIndex(
        (item) => item?._id === existUser?._id
      );
      const tempData = [...currentUserList?.data];
      if (existUserIndex != -1) {
        tempData[existUserIndex] = existUser;
        setCurrentUserList((prev) => ({ ...prev, data: tempData }));
        setExistUser(false);
      } else {
        setCurrentUserList((prev) => ({
          ...prev,
          data: [existUser, ...prev.data],
        }));
        setExistUser(false);
      }
    }
  }, [existUser]);

  useEffect(() => {
    socket.on("all_live_user", (currentData) => {
      if (currentData?.existUser) {
        setExistUser(currentData);
      } else {
        setCurrentUserList((prev) => ({
          ...prev,
          data: [...prev?.data, currentData],
        }));
      }
    });
    return () => {
      socket.off("all_live_user");
    };
  }, []);

  useEffect(() => {
    if (user?.userType) {
      getAllLiveUser({});
    }
  }, [user]);

  useEffect(() => {
    if (allLiveUser) {
      setCurrentUserList(allLiveUser);
    }
  }, [allLiveUser]);

  const startChat = (currentuser) => {
    dispatch(startOpenChatAction(currentuser));
  };

  const onPagination = (page) => {
    getAllLiveUser({ page });
  };
  return (
    <>
      <Card className="h-100 live-user-tracker">
        <Card.Header className="bg-white py-4">
          <h4 className="mb-0">
            <Tooltip title={"Graph View"}>
              <span className="pointer">
                <Icons title={"grid-view"} color="black" className={"mr-2"} />
              </span>
            </Tooltip>
            <div className="live-user-counter">
              <LiveUserSelectWebsite
                filter={filter}
                getAllLiveUser={getAllLiveUser}
                setFilter={setFilter}
              />
              <LiverUserFilterChips
                label={`Total User (${
                  currentUserList?.paginations?.total || 0
                })`}
                backgroundColor="blue"
                className="ml-3 mt-2"
              />
              <LiverUserFilterChips
                label={`Online Users (${
                  currentUserList?.paginations?.onlineUsers || 0
                })`}
                className="ml-3 mt-2"
                isFilled={filter?.isOnline}
                onClick={() => {
                  setFilter((prev) => ({ ...prev, isOnline: !prev?.isOnline }));
                  getAllLiveUser({ tempIsOnline: !filter?.isOnline });
                }}
              />
              <LiverUserFilterChips
                label={`Today's User (${
                  currentUserList?.paginations?.todayUserCount || 0
                })`}
                backgroundColor="#0a376a"
                className="ml-3 mt-2"
                isFilled={filter?.onlyTodayUser}
                onClick={() => {
                  setFilter((prev) => ({
                    ...prev,
                    onlyTodayUser: !prev?.onlyTodayUser,
                  }));
                  getAllLiveUser({ tempOnlyTodayUser: !filter?.onlyTodayUser });
                }}
              />
              <DateRangeFilter
                filter={filter}
                setFilter={setFilter}
                getAllLiveUser={getAllLiveUser}
                currentUserList={currentUserList}
              />
            </div>
          </h4>
        </Card.Header>
        {/* <Card.Body>
        <PieGraph />
        </Card.Body> */}

        {!!currentUserList?.data?.length && (
          <Table responsive className="text-nowrap">
            <thead className="table-light">
              <tr>
                <th>Sr. No</th>
                <th>User Name</th>
                <th>Online</th>
                <th>Action</th>
                <th>Website</th>
                <th>Logged Time</th>
                <th>Email</th>
                <th>Number</th>
                <th>Country Name</th>
                <th>State Code</th>
                <th>City</th>
                <th>Visit Count</th>
                <th>OS</th>
                <th>Browser</th>
                <th>Page</th>
                <th>Refral Website</th>
                <th>IP</th>
                <th>Device Type</th>
              </tr>
            </thead>
            <tbody>
              {currentUserList?.data
                ?.filter((item) => {
                  if (filter?.isOnline === true) {
                    if (item?.isOnline) {
                      return item;
                    }
                  } else {
                    return item;
                  }
                })
                .map((item, index) => {
                  return (
                    <tr key={index + 19393 * 1}>
                      <td className="align-middle">{index + 1}</td>
                      <td className="align-middle">{item?.name || "NA"}</td>
                      <td
                        className={`align-middle ${
                          item?.isOnline ? "text-success" : "text-danger"
                        }`}
                      >
                        {item?.isOnline ? "Online" : "Offline"}
                      </td>

                      <td className="align-middle">
                        <Buttons
                          title={"Start Chat"}
                          size="small"
                          onClick={() => startChat(item)}
                        />
                      </td>
                      <td className="align-middle ">{item?.website}</td>
                      <td className="align-middle ">
                        {returnDateWithTime(item?.timestamp)}
                      </td>
                      <td className="align-middle pointer">
                        {item?.email || "NA"}
                      </td>
                      <td className="align-middle pointer">
                        {item?.number || "NA"}
                      </td>
                      <td
                        className="align-middle"
                        style={{ maxWidth: "150px", overflow: "hidden" }}
                      >
                        {item?.countryName || "NA"}
                      </td>
                      <td
                        className="align-middle"
                        style={{ maxWidth: "150px", overflow: "hidden" }}
                      >
                        {item?.state || "NA"}
                      </td>
                      <td
                        className="align-middle"
                        style={{ maxWidth: "150px", overflow: "hidden" }}
                      >
                        {item?.city || "NA"}
                      </td>
                      <td className="align-middle">{item?.visits || "NA"}</td>
                      <td className="align-middle ">{item?.platform}</td>
                      <td className="align-middle">{item?.browser}</td>

                      <td className="align-middle">
                        <Tooltip title={item?.page}>
                          {truncateString(item?.page, 39)}
                        </Tooltip>
                      </td>
                      <td className="align-middle">
                      <Tooltip title={item?.refralUrl}>
                          {truncateString(item?.refralUrl, 39)}
                        </Tooltip></td>
                      <td className="align-middle">{item?.clientIp}</td>
                      <td className="align-middle">{item?.deviceType}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        )}
        {currentUserList?.paginations && allLiveUser?.data?.length > 0 && (
          <div className="custom-pagination mt-3">
            <Paginations
              onChange={onPagination}
              pagination={currentUserList?.paginations}
            />
          </div>
        )}
      </Card>
    </>
  );
};

export default LiveUserTrack;
