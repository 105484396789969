import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "../view/auth/Login";
import ManagerDashboard from "../view/manager/ManagerDashboard";
import Logout from "../view/auth/Logout";
import Error from "../view/Error";
import OperatorList from "../view/manager/OperatorList";
import ClientList from "../view/manager/ClientList";
import ExpertList from "../view/manager/ExpertList";
import LeadList from "../view/manager/LeadList";
import Orderlist from "../view/manager/Orderlist";
import OperatorDashboard from "../view/operator/Operator";
import LeadListOperator from "../view/operator/LeadList";
import OrderListOperator from "../view/operator/OrderList";
import ClientDashboard from "../view/client/ClientDashboard";
import LeadListClient from "../view/client/LeadList";
import OrderListClient from "../view/client/OrderList";
import OrderListExpert from "../view/experts/OrderListExpert";
import ExpertDashboard from "../view/experts/ExpertDashboard";
import ResetPassword from "../view/auth/ResetPassword";
import UpdatePassword from "../view/auth/UpdatePassword";
import SuperAdminDashboard from "../view/super-admin/SuperAdminDashboard";
import OperatorListSA from "../view/super-admin/OperatorListSA";
import ClientListSA from "../view/super-admin/ClientListSA";
import ExpertListSA from "../view/super-admin/ExpertListSA";
import LeadListSA from "../view/super-admin/LeadListSA";
import OrderlistSA from "../view/super-admin/OrderlistSA";
import ManagerList from "../view/super-admin/ManagerList";
import AdminList from "../view/super-admin/AdminList";
import ChatWithUs from "../view/chat/ChatWithUs";
import OpenLeadExpert from "../view/experts/OpenLeadExpert";
import LiveUser from "../view/super-admin/LiveUser";
const CustomRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/update-password/:resetId/:userId" element={<UpdatePassword />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/manager/dashboard" element={<ManagerDashboard />} />
        <Route path="/manager/operators" element={<OperatorList />} />
        <Route path="/manager/clients" element={<ClientList />} />
        <Route path="/manager/experts" element={<ExpertList />} />
        <Route path="/manager/leads" element={<LeadList />} />
        <Route path="/manager/orders" element={<Orderlist />} />
        <Route path="/manager/live-users" element={<LiveUser/>}/>

        {/* operator */}
        <Route path="/operator/dashboard" element={<OperatorDashboard />} />
        <Route path="/operator/leads" element={<LeadListOperator />} />
        <Route path="/operator/orders" element={<OrderListOperator />} />
        <Route path="/operator/live-users" element={<LiveUser/>}/>

        {/* operator */}
        <Route path="/client/dashboard" element={<ClientDashboard />} />
        <Route path="/client/leads" element={<LeadListClient />} />
        <Route path="/client/orders" element={<OrderListClient />} />
   

        {/* expert */}
        <Route path="/expert/dashboard" element={<ExpertDashboard />} />
        <Route path="/expert/orders" element={<OrderListExpert />} />
        <Route path="/expert/open-lead" element={<OpenLeadExpert />} />
        <Route path="*" element={<Error />} />



        {/* SUPER ADMIN */}
        <Route path="/super-admin/dashboard" element={<SuperAdminDashboard />} />
        <Route path="/super-admin/operators" element={<OperatorListSA />} />
        <Route path="/super-admin/clients" element={<ClientListSA />} />
        <Route path="/super-admin/experts" element={<ExpertListSA />} />
        <Route path="/super-admin/managers" element={<ManagerList />} />
        <Route path="/super-admin/admins" element={<AdminList />} />
        <Route path="/super-admin/leads" element={<LeadListSA />} />
        <Route path="/super-admin/orders" element={<OrderlistSA />} />
        <Route path="/super-admin/live-users" element={<LiveUser/>}/>


        {/* ADMIN */}
        <Route path="/admin/dashboard" element={<SuperAdminDashboard />} />
        <Route path="/admin/operators" element={<OperatorListSA />} />
        <Route path="/admin/clients" element={<ClientListSA />} />
        <Route path="/admin/experts" element={<ExpertListSA />} />
        <Route path="/admin/managers" element={<ManagerList />} />
        <Route path="/admin/leads" element={<LeadListSA />} />
        <Route path="/admin/orders" element={<OrderlistSA />} />

        <Route path="/admin/live-users" element={<LiveUser/>}/>



        <Route path="/chat" element={<ChatWithUs />}/>
        
      </Routes>
    </Router>
  );
};

export default CustomRouter;
