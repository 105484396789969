import React, { useEffect, useState } from "react";
import Icons from "../../components/utils/Icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserAction,
  loginAction,
  resetPasswordAction,
} from "../../services/action/authentication";
import { Link, useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const [obj, setObj] = useState({
    email: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isReset, user } = useSelector((state) => state.authenticationReducer);

  const [errors, setErrors] = useState({
    email: "",
  });

  const checkLogin = (tempUser) => {
    if (tempUser && tempUser?.userType === "MANAGER") {
      navigate("/manager/dashboard");
    } else if (tempUser && tempUser?.userType === "OPERATOR") {
      navigate("/operator/dashboard");
    } else if (tempUser && tempUser?.userType === "CLIENT") {
      navigate("/client/dashboard");
    } else if (tempUser && tempUser?.userType === "EXPERT") {
      navigate("/expert/dashboard");
    }
  };

  useEffect(() => {
    let tokens=localStorage.getItem("token")
    tokens && dispatch(getUserAction());
  }, []);

  useEffect(() => {
    checkLogin(user);
  }, [user]);

  const callBackSuccess = (res) => {
    checkLogin(res);
  };

  const resetNow = (e) => {
    e.preventDefault();
    let tempError = { ...errors };
    if (!obj.email) {
      tempError.email = "Email is required!";
    } else {
      tempError.email = "";
    }


    if (tempError.email) {
      setErrors(tempError);
      return false;
    }
    dispatch(resetPasswordAction({ data: obj, cb: callBackSuccess }));
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-purple-400 via-pink-500 to-red-500">
      <div className="max-w-lg w-full space-y-8 m-4 p-10 bg-white rounded-xl shadow-lg z-10">
        <div className="text-center">
          <img src="/images/logo.png" alt=""/>
          {/* <h2 className="mt-6 text-3xl font-bold text-gray-900">
            Welcome Back!
          </h2> */}
          {/* <p className="mt-2 text-sm text-gray-600">Please sign in to your account</p> */}
        </div>
        <form className="mt-8 space-y-6" onSubmit={resetNow}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="relative">
            <label className="text-sm font-bold text-gray-700 tracking-wide">Email</label>
            <input
              className="w-full text-base py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
              type="email"
              placeholder="mail@gmail.com"
              value={obj.email}
              onChange={(e) => setObj({ ...obj, email: e.target.value })}
            />
            {errors.email && (
              <span className="text-red-500 text-xs">{errors.email}</span>
            )}
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
            </div>
            <div className="text-sm">
              <Link to={"/"} className="font-medium text-indigo-600 hover:text-indigo-500">
                Login Page
              </Link>
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="w-full flex justify-center btn-primary text-gray-100 p-4 rounded-full tracking-wide font-semibold shadow-lg cursor-pointer transition ease-in duration-500"
            >
              {isReset ? <Icons title="loader" /> : "Reset Password"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;