import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestForAssignLeadAction } from "../../../../../services/action/common";
import Buttons from "../../../../utils/buttons/Buttons";
import EditorText from "../../../../utils/text-editor/EditorText";
import LeadCard from "../../../../card/LeadCard";

const RequestLeadId = ({ lead, onClose }) => {
  const { user } = useSelector((state) => state.authenticationReducer);
  const {isAssignRquest}=useSelector((state)=>state.commonReducer)
  const [comment,setComment]=useState("")
  const dispatch = useDispatch();
  const requestForAssign = () => {
    dispatch(
      requestForAssignLeadAction({
        userType: user?.userType?.toLowerCase(),
        data: {
          leadId: lead?._id,
          expertId: user?._id,
          comment
        },
        cb: onClose,
      })
    );
  };
  return (
    <div className="row">
      <div className="col-12">
      <LeadCard
            hideAction={true}

            data={lead}
            close={onClose}
          />
      </div>
      <div className="col-12">
        <label>Leave Your Comment</label>
        <EditorText value={comment} setValue={(value)=>setComment(value)}/>
      </div>
      <div className="col-16 mt-2 text-center">
        <Buttons isLoading={isAssignRquest} disabled={isAssignRquest} title={"Apply"} onClick={requestForAssign} />
      </div>
    </div>
  );
};

export default RequestLeadId;
