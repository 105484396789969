import React, { useRef, useState } from "react";
import Icons from "../../components/utils/Icons";
import { useDispatch, useSelector } from "react-redux";
import { uploadImageAction } from "../../services/action/common";

const UploadFile = ({onSubmit}) => {
  const { isImageLoading } = useSelector((state) => state.commonReducer);
  const dispatch = useDispatch();
  const fileRef = useRef();

  
  const onSend = (images) => {
    let messageFile = "";
    images?.map((item) => {
      messageFile =
        messageFile +
        `<a target='_blank' href=${item}><i class="fa fa-file" aria-hidden="true"></i></a>`;
    });

    const message = `<div class="delivery-chat-box"><p class="file-list">${messageFile} </p></div>`;
    onSubmit(message)
  };

  const uploadImage = (e) => {
    const callBackImage = (res) => {
    //   let tempData = [...imageUrls];
    //   setImageUrls([...tempData, ...res?.imageUrls]);
      onSend(res?.imageUrls)
    };
    let formData = new FormData();
    for (let i = 0; i < e.target.files.length; i++) {
      formData.append("images", e.target.files[i]);
    }
    dispatch(uploadImageAction({ data: formData, cb: callBackImage }));
    e.target.value = null;
  };

  return (
    <span className="chat-file-btn pointer" onClick={()=>fileRef.current?.click()}>
      <input
        type="file"
        onChange={uploadImage}
        className="display-none"
        ref={fileRef}
        multiple={true}
      />
      <Icons
        title={isImageLoading ? "loader" : "file"}
        color={"#cccccc"}
        size={isImageLoading?25:32}
      />
    </span>
  );
};

export default UploadFile;
