import { useDispatch, useSelector } from "react-redux";
import SelectBox from "../../../utils/select/SelectBox";
import Buttons from "../../../utils/buttons/Buttons";
import { useEffect, useState } from "react";
import { changeCallHippoPermissionAction } from "../../../../services/action/authentication";

const FormCallHippoPermission = () => {
  const { operListOption } = useSelector((state) => state.commonReducer);
  const { user } = useSelector((state) => state.authenticationReducer);
  const [selectedUser, setSelectedUser] = useState({});

  useEffect(() => {
    if (operListOption?.length) {
      let tempData = operListOption?.find((item) => item?.isAccess);
      setSelectedUser(tempData);
    }
  }, [operListOption]);
  const dispatch = useDispatch();

  const updatePermission = () => {
    const userType = user?.userType?.toLowerCase();
    dispatch(
      changeCallHippoPermissionAction({ userType, data: {userId:selectedUser?.value} })
    );
  };
  return (
    <div className="form">
      <div className="row">
        <div className="col-sm-12">
          <label>Transfer Permission</label>
          <SelectBox
            onChange={(data) => setSelectedUser(data)}
            options={operListOption || []}
            value={selectedUser}
          />
        </div>
        <div className="col-sm-12 text-center mt-2">
          <Buttons title={"Update  Permission"} onClick={updatePermission} />
        </div>
      </div>
    </div>
  );
};

export default FormCallHippoPermission;
