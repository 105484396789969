import React, { useState } from "react";
import Icons from "../../utils/Icons";
import { Menu, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { startChatAction } from "../../../services/action/common";
import CustomModal from "../../utils/modals/CustomModal";
import FormEditLeadGenerate from "./components/FormEditLeadGenerate";
import FullScreenModal from "../../utils/modals/FullScreenModal";
import LeadCard from "../../card/LeadCard";

const LeadActionButtons = ({isOpenLeadOnly, page, lead, afterSave,setShowExpertAssign }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [leadId, setLeadId] = useState("");
  const { user } = useSelector((state) => state.authenticationReducer);
  const isManagerOrOperator =user?.userType === "OPERATOR" || user?.userType === "MANAGER";
    const isAdminOrSuperAdmin =
    user?.userType === "ADMIN" || user?.userType === "SUPER_ADMIN";


  const isClient = user?.userType === "CLIENT";
  const [leadDetails, setLeadDetails] = useState({
    modal: false,
    edit: false,
    preview: false,
    data: "",
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeButton = () => {
    setLeadDetails({})
    afterSave();
  };

  const dispatch = useDispatch();
  return (
    <div className="box">
      <CustomModal
        title={`Update ${page === "order" ? "Order" : "Lead"}`}
        bgContent="dark-bg"
        open={leadDetails.modal}
        onClose={() => setLeadDetails({})}
        content={() => (
          <FormEditLeadGenerate
            page={page}
            data={leadDetails?.data}
            close={closeButton}
          />
        )}
      />

      <FullScreenModal
        title={"Personal Details"}
        bgContent="dark-bg"
        size="xl"
        open={leadId}
        onClose={() => setLeadId("")}
        content={() => <LeadCard data={leadId} close={() => setLeadId("")} />}
      />
     

      <span onClick={handleClick}>
        <Icons title={"three-dots"} className={"mt-2"} color="#0a376a" />
      </span>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={() => {
            setLeadId(lead);
            handleClose();
          }}
        >
          View Details
        </MenuItem>
        {(isManagerOrOperator || isClient || isAdminOrSuperAdmin) && (
          <MenuItem
            onClick={() => {
              setLeadDetails({
                edit: true,
                modal: true,
                data: lead,
              });
              handleClose();
            }}
          >
            Edit
          </MenuItem>
        )}
        {!isOpenLeadOnly && <MenuItem
          onClick={() => {
            dispatch(startChatAction({ leadDetail: lead, userId: user?._id }));
            handleClose();
          }}
        >
          Start Chat
        </MenuItem>}
       {(isManagerOrOperator || isAdminOrSuperAdmin) && (<MenuItem
          onClick={() => {
            setShowExpertAssign(lead)
            handleClose();
          }}
        >
         Assign To Expert
        </MenuItem>)}
      </Menu>
    </div>
  );
};

export default LeadActionButtons;
