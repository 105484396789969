import {
	Briefcase,
    ListTask,
    People,
    Bullseye
} from 'react-bootstrap-icons';

export const ProjectsStats = (data)=>[
    {
       id:1,
       title : "Client",
       value : data?.clients,
       icon: <Briefcase size={18}/>,
       statInfo: '<span className="text-dark me-2">2</span> Completed' 
    },
    {
        id:2,
        title : "Operators",
        value : data?.operators,
        icon: <ListTask size={18}/>,
        statInfo: '<span className="text-dark me-2">28</span> Completed' 
     },
     {
        id:3,
        title : "Experts",
        value : data?.experts,
        icon: <People size={18}/>,
        statInfo: '<span className="text-dark me-2">1</span> Completed' 
     },
     {
        id:4,
        title : "Leads",
        value : data?.leads,
        icon: <Bullseye size={18}/>,
        statInfo: '<span className="text-dark me-2">5%</span> Completed' 
     }
];
export default ProjectsStats;

export const DashboardCardClient = (data)=>[
    {
       id:1,
       title : "Orders",
       value : data?.leads,
       icon: <People size={18}/>,
       statInfo: '<span className="text-dark me-2">1</span> Completed' 
    }
];

export const DashboardCardExpert = (data)=>[
   {
      id:2,
      title : "Total Orders",
      value : data?.totalOrders,
      icon: <Bullseye size={18}/>,
      statInfo: '<span className="text-dark me-2">5%</span> Completed' 
   },
   {
      id:2,
      title : "Completed Orders",
      value : data?.completedOrders,
      icon: <Bullseye size={18}/>,
      statInfo: '<span className="text-dark me-2">5%</span> Completed' 
   },
   {
      id:2,
      title : "Today's Deadline orders",
      value : data?.todayDeadlineLeads || 0,
      icon: <Bullseye size={18}/>,
      statInfo: '<span className="text-dark me-2">5%</span> Completed' 
   },
   {
      id:2,
      title : "Deadline missed orders",
      value : data?.missedOrder,
      icon: <Bullseye size={18}/>,
      statInfo: '<span className="text-dark me-2">5%</span> Completed' 
   }
];
export const DashboardCardOperator = (data)=>[
   {
      id:1,
      title : "Client",
      value : data?.clients,
      icon: <Briefcase size={18}/>,
      statInfo: '<span className="text-dark me-2">2</span> Completed' 
   },
   {
       id:2,
       title : "Operators",
       value : data?.operators,
       icon: <ListTask size={18}/>,
       statInfo: '<span className="text-dark me-2">28</span> Completed' 
    },
    {
       id:3,
       title : "Experts",
       value : data?.experts,
       icon: <People size={18}/>,
       statInfo: '<span className="text-dark me-2">1</span> Completed' 
    },
    {
       id:4,
       title : "Leads",
       value : data?.leads,
       icon: <Bullseye size={18}/>,
       statInfo: '<span className="text-dark me-2">5%</span> Completed' 
    }
];