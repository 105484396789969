import React, { Fragment, useState } from "react";
import LiverUserFilterChips from "./LiverUserFilterChips";
import CustomModal from "../../utils/modals/CustomModal";
import CustomDatePicker from "../../utils/date-picker/CustomDatePicker";
import Buttons from "../../utils/buttons/Buttons";
import { returnDateOnlyDate } from "../../utils/functions/functions";

const DateRangeFilter = ({ filter, setFilter, getAllLiveUser,currentUserList }) => {
  const [openDateFilter, setOpenDateFilter] = useState(false);
  const applyFilter = () => {
    getAllLiveUser({});
    setOpenDateFilter(false)
  };
  const Content = () => {
    return (
      <Fragment className="form">
        <div className="row">
          <div className="col-6">
            <label>From</label>
            <CustomDatePicker
              value={filter?.from}
              setValue={(date) =>
                setFilter((prev) => ({ ...prev, from: date }))
              }
            />
          </div>
          <div className="col-6">
            <label>To</label>
            <CustomDatePicker
              value={filter?.to}
              setValue={(date) => setFilter((prev) => ({ ...prev, to: date }))}
            />
          </div>

          <div className="col-12 mt-3 text-center">
            <Buttons title={"Apply"} onClick={applyFilter} />
          </div>
        </div>
      </Fragment>
    );
  };
  return (
    <Fragment>
      <CustomModal
        size="small"
        open={openDateFilter}
        content={() => <Content />}
        onClose={() => setOpenDateFilter(false)}
      />
      <LiverUserFilterChips
        label={
          filter?.from
            ? `${returnDateOnlyDate(filter?.from)} - ${returnDateOnlyDate(
                filter?.to
              )} ${currentUserList?.paginations?.totalFilterCount?"(Users "+currentUserList?.paginations?.totalFilterCount+")":""}`
            : "Select Date Range"
        }
        backgroundColor="black"
        className="ml-3 mt-2"
        isFilled={filter?.from || filter?.to}
        onClick={() => setOpenDateFilter(true)}
      />
    </Fragment>
  );
};

export default DateRangeFilter;
