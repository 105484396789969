import React from 'react';

const DashboardSkeleton = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      {/* Header */}
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex items-center justify-between">
          <div className="flex items-center">
            <div className="w-8 h-8 bg-gray-300 rounded-md animate-pulse mr-4"></div>
            <div className="w-64 h-8 bg-gray-300 rounded-md animate-pulse"></div>
          </div>
          <div className="flex items-center space-x-4">
            <div className="w-8 h-8 bg-gray-300 rounded-full animate-pulse"></div>
            <div className="w-8 h-8 bg-gray-300 rounded-full animate-pulse"></div>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        {/* Projects Title */}
        <h1 className="text-2xl font-semibold mb-6 w-32 h-8 bg-gray-300 rounded animate-pulse"></h1>

        {/* Project Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
          {['Client', 'Operators', 'Experts', 'Leads'].map((title, index) => (
            <div key={index} className="bg-white p-4 rounded-lg shadow">
              <div className="flex justify-between items-center mb-2">
                <div className="w-24 h-6 bg-gray-300 rounded animate-pulse"></div>
                <div className="w-8 h-8 bg-gray-300 rounded-md animate-pulse"></div>
              </div>
              <div className="w-16 h-8 bg-gray-300 rounded animate-pulse"></div>
            </div>
          ))}
        </div>

        {/* Active Leads Section */}
        <div className="bg-white shadow rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 className="w-32 h-6 bg-gray-300 rounded animate-pulse"></h2>
          </div>
          <div className="border-t border-gray-200">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {['Lead Id', 'Lead Name', 'Status', 'Client Deadline', 'Expert Deadline', 'Reg. Date'].map((header, index) => (
                    <th key={index} scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <div className="w-20 h-4 bg-gray-300 rounded animate-pulse"></div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                <tr>
                  <td colSpan="6" className="px-6 py-4 whitespace-nowrap text-center">
                    <div className="w-32 h-6 bg-gray-300 rounded animate-pulse mx-auto"></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Footer */}
        <div className="mt-8 flex justify-between text-sm text-gray-500">
          <div className="w-32 h-4 bg-gray-300 rounded animate-pulse"></div>
          <div className="w-32 h-4 bg-gray-300 rounded animate-pulse"></div>
        </div>
      </main>
    </div>
  );
};

export default DashboardSkeleton;