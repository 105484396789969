import { Row, Col, ListGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import {
  getNotificationListAction,
  startChatAction,
  startOpenChatAction,
  updateNotificationStatusAction,
} from "../../../../services/action/common";

const NotificationList = () => {
  const { notificationList } = useSelector((state) => state.commonReducer);
  const { user } = useSelector((state) => state.authenticationReducer);
  const dispatch = useDispatch();
  const userType = user?.userType?.toLowerCase();

  const cbNotification = () => {
    dispatch(getNotificationListAction({ userType }));
  };
  const handleOpenChat = (
    leadId,
    subject,
    senderName,
    messageId,
    notificationId,
    isNew,
    orderId
  ) => {
    if (isNew) {
      dispatch(
        updateNotificationStatusAction({
          userType,
          _id: notificationId,
          cb: cbNotification,
        })
      );
    }
    dispatch(
      startChatAction({
        leadDetail: { senderName, orderId, messageId, _id: leadId, subject },
        userId: user?._id,
      })
    );
  };

  const startOpenChat=({userId,notificationId,isNew})=>{
    if (isNew) {
      dispatch(
        updateNotificationStatusAction({
          userType,
          _id: notificationId,
          cb: cbNotification,
        })
      );
    }
     dispatch(startOpenChatAction({_id:userId}))
  }
  return (
    <SimpleBar style={{ maxHeight: "300px" }}>
      <ListGroup variant="flush">
        {notificationList?.data?.map(function (item, index) {
          return (
            <ListGroup.Item
              className={!item?.isSeen ? "bg-light" : ""}
              key={index}
            >
              <Row>
                <Col>
                  <Link
                    href="#"
                    className="text-muted"
                    onClick={() =>
                    {
                      if(item?.messageType == "EXTERNAL"){
                        startOpenChat({
                          userId:item?.userId,
                          isNew:!item?.isSeen,
                          notificationId:item?._id
                        })
                      }else{
                      handleOpenChat(
                        item?.leadId,
                        item?.subject,
                        item?.senderName,
                        item?.messageId,
                        item?._id,
                        !item?.isSeen,
                        item?.orderId
                      )
                    }
                    }}
                  >
                    <h5 className=" mb-1 ">
                      {/* <Badge badgeContent={"New"} color="secondary" className="notification-badge"> */}
                      {!item?.isSeen && (
                        <span className="notification-badge">•</span>
                      )}
                      {item?.senderName} (#{item?.orderId}){/* </Badge> */}
                    </h5>
                    <p
                      className="mb-0"
                      dangerouslySetInnerHTML={{ __html: item?.message }}
                    ></p>
                  </Link>
                </Col>
              </Row>
            </ListGroup.Item>
          );
        })}
        {notificationList?.data?.length < 1 && (
          <p className="pl-4">Notification is empty</p>
        )}
      </ListGroup>
    </SimpleBar>
  );
};

export default NotificationList;
