import React, { Fragment, useEffect, useState, useMemo, useCallback } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { SidebarOptionSuperAdmin, DashboardMenuClient, DashboardMenuExpert, DashboardMenuManager, DashboardMenuOperator, SidebarOptionAdmin } from "../../utils/LayoutConstant";
import { useSelector } from "react-redux";
import Icons from "../../utils/Icons";

const NavbarVertical = () => {
  const { user } = useSelector((state) => state.authenticationReducer);
  const location = useLocation();
  const navigate = useNavigate();

  const memoizedSideBar = useMemo(() => {
    if (user?.userType === "MANAGER") {
      return DashboardMenuManager;
    } else if (user?.userType === "CLIENT") {
      return DashboardMenuClient;
    } else if (user?.userType === "OPERATOR") {
      return DashboardMenuOperator;
    } else if (user?.userType === "EXPERT") {
      return DashboardMenuExpert;
    }else if(user?.userType==="SUPER_ADMIN"){
      return SidebarOptionSuperAdmin;
    }else if(user?.userType==="ADMIN"){
      return SidebarOptionAdmin;
    }
    return [];
  }, [user?.userType]);

  const [sideBar] = useState(memoizedSideBar);

  const openMenus = useMemo(() => {
    const currentPath = location.pathname;
    const newOpenMenus = {};
    sideBar.forEach(menu => {
      if (menu.children) {
        const isChildActive = menu.children.some(child => child.link === currentPath);
        if (isChildActive) {
          newOpenMenus[menu.title] = true;
        }
      }
    });
    return newOpenMenus;
  }, [location.pathname, sideBar]);

  const [menuState, setMenuState] = useState(openMenus);

  useEffect(() => {
    setMenuState(openMenus);
  }, [openMenus]);

  const toggleMenu = useCallback((menuTitle) => {
    setMenuState(prev => ({ ...prev, [menuTitle]: !prev[menuTitle] }));
  }, []);

  const handleNavigation = useCallback((link, menuTitle) => {
    navigate(link);
    if (menuTitle) {
      setMenuState(prev => ({ ...prev, [menuTitle]: true }));
    }
  }, [navigate]);

  const getRelevantIcon = useCallback((menuTitle) => {
    switch (menuTitle.toLowerCase()) {
      case 'users': return 'users';
      case 'lead orders': return 'clipboard';
      case 'analytics': return 'bar-chart-2';
      case 'orders': return 'shopping-cart';
      case 'subscribers': return 'user-plus';
      case 'payouts': return 'credit-card';
      case 'posts': return 'file-text';
      case 'logout': return 'log-out';
      case "live users": return "activity"
      default: return 'circle';
    }
  }, []);

  return (
    <Fragment>
      <SimpleBar className="h-screen text-gray-100">
        <nav className="py-4">
          <div className="px-4 mb-6">
            <Link to="/" className="flex items-center justify-center">
              <img src="/assets/images/logo.png" alt="Clickinpedia" className="h-10 w-auto filter invert" />
            </Link>
          </div>
          <ul className="space-y-2 px-2">
            {sideBar.map((menu, index) => (
              <li key={index}>
                <div
                  className={`flex items-center justify-between p-2 rounded-lg cursor-pointer transition-colors duration-150 ease-in-out
                    ${menu.link === location.pathname
                      ? "bg-secondary-2 text-white"
                      : "text-gray-300 hover:bg-gray-800"
                    }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (menu.children) {
                      toggleMenu(menu.title);
                    } else if (menu.link) {
                      handleNavigation(menu.link);
                      menu?.callFunction && menu?.callFunction()
                    } 
                  }}
                >
                  <div className="flex items-center space-x-3">
                    <i className={`fe fe-${menu.title.toLowerCase() === 'dashboard' ? menu.icon : getRelevantIcon(menu.title)} text-lg`}></i>
                    <span className="font-medium" onClick={()=>menu?.callFunction && menu?.callFunction()}>{menu.title}</span>
                  </div>
                  {menu.children && (
                    <i className={`fe fe-chevron-${menuState[menu.title] ? 'up' : 'down'} text-sm`}></i>
                  )}
                </div>
                {menu.children && menuState[menu.title] && (
                  <ul className="mt-2 ml-6 space-y-1">
                    {menu.children.map((child, childIndex) => (
                      <li key={childIndex}>
                        <div
                          className={`p-2 rounded-md cursor-pointer transition-colors duration-150 ease-in-out
                            ${child.link === location.pathname
                              ? "bg-gray-700 text-white"
                              : "text-gray-400 hover:bg-gray-800 hover:text-gray-200"
                            }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleNavigation(child.link, menu.title);
                          }}
                        >
                          {child.name}
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </SimpleBar>
    </Fragment>
  );
};

export default React.memo(NavbarVertical);