
import moment from "moment/moment"


export const returnDateWithTime = (date) => {
  if (!date) {
    return "NA";
  }
  const time = moment(date);
  return time.format('DD MMM YY h:mmA');
};
export const returnDateOnlyDate = (date) => {
  if (!date) {
    return "NA";
  }
  const time = moment(date);
  return time.format('DD MMM YY');
};

export const  getFileNameFromUrl =(url) =>{
    const parts = url.split('/');
    return parts[parts.length - 1];
  }

export const sanitizeMessage=(msg, data)=>{
  let checkPaymentLink=data?.oldMessage?.includes("PAYAMENT_LINK") || data?.oldMessage?.includes("DOCUMENT_LINK");
  if(checkPaymentLink){
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = data?.oldMessage;
    const linkElement = tempDiv.firstChild;
    linkElement.textContent=msg;
    return linkElement.outerHTML;
  }else{
    return msg;
  }
}

export const truncateString = (inputString,maxStringLength=6) => {
  if (!inputString) {
    return "";
  }
  if (inputString.length > (maxStringLength+4)) {
    return inputString.slice(0, maxStringLength) + "...";
  }

  return inputString;
};

export const returnFirstDigit =(str)=>{
  if(!str){
   return "NA";
  }else{
    return str[0]?.toUpperCase();
  }
}

export const removeUnnecessaryTags = (str) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, 'text/html');

  const paragraphs = Array.from(doc.querySelectorAll('p'));

  // Filter out only paragraphs that are either empty or contain only <br> at the start and end
  const filteredParagraphs = paragraphs.filter((p, index) => {
    const innerHTML = p.innerHTML.trim();
    const isEmptyOrBreak = innerHTML === '<br>' || innerHTML === '';

    // Remove <p><br></p> or empty <p></p> if it's at the start or end
    if (isEmptyOrBreak && (index === 0 || index === paragraphs.length - 1)) {
      return false;
    }

    return true;
  });

  // Reconstruct the HTML with the preserved tags
  const filteredHTML = filteredParagraphs.map(p => p.outerHTML).join('');

  // Preserve other tags like <h1>, <h2>, <h3>, <a>, etc., in the final output
  const preservedHTML = doc.body.innerHTML.replace(/<p><br><\/p>/g, '').replace(/<p><\/p>/g, '');

  return preservedHTML;
};

export function isMissedExpertDeadline(expertDeadline,orderStatus) {
  const expertDate = moment(expertDeadline);
  const currentDate=moment();
  return currentDate.isAfter(expertDate) && (orderStatus!=="DONE" && orderStatus!=="DELIVERED");
}


export const sortByOnlineStatus =(users) =>{
  return users?.sort((a, b) => {
    return (b.isOnline === true) - (a.isOnline === true);
  });
}


export const formatMessageTime = (timestamp) => {
  const now = moment();
  const messageTime = moment(timestamp);
  const differenceInMinutes = now.diff(messageTime, 'minutes');

  if (differenceInMinutes < 1) {
    return 'just now';
  } else if (differenceInMinutes < 60) {
    return `${differenceInMinutes} minute${differenceInMinutes === 1 ? '' : 's'} ago`;
  } else if (now.diff(messageTime, 'hours') < 24) {
    return `${now.diff(messageTime, 'hours')} hour${now.diff(messageTime, 'hours') === 1 ? '' : 's'} ago`;
  } else if (now.diff(messageTime, 'days') < 7) {
    return `${now.diff(messageTime, 'days')} day${now.diff(messageTime, 'days') === 1 ? '' : 's'} ago`;
  } else if (now.diff(messageTime, 'years') < 1) {
    return messageTime.format('D MMM');
  } else {
    return messageTime.format('D MMM YYYY');
  }
}


export const validatePhoneNumber = (phone) => {
  const phoneRegex = /^[0-9]{9,12}$/;
  return phoneRegex.test(phone);
};

export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const validateName = (name) => {
  const nameRegex = /^[a-zA-Z\s]{2,50}$/;
  return nameRegex.test(name);
};