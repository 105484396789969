import React, { useRef, useState } from "react";
import { Card, Table } from "react-bootstrap";
import Buttons from "../../utils/buttons/Buttons";
import CustomModal from "../../utils/modals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import FormEditLeadGenerate from "./components/FormEditLeadGenerate";
import HeaderChips from "./components/HeaderChips";
import Icons from "../../utils/Icons";
import { returnDateWithTime } from "../../utils/functions/functions";
import Paginations from "../../utils/pagination/Paginations";
import FullScreenModal from "../../utils/modals/FullScreenModal";
import PersonalCard from "../../card/PersonalCard";
import { LeadFilter } from "../../utils/TeamsData";
import { startChatAction } from "../../../services/action/common";
import OperatorSkeleton from "../../skeleton/OperatorSkeleton";
import LeadActionButtons from "./LeadActionButtons";
import RangeDateFilter from "./components/RangeDateFilter";
import AssignExpert from "./components/AssignExpert";

const LeadListComponent = ({
  getList,
  TeamsData,
  listTitle = "Lead List",
  addButtonTitle = "Add New Lead",
}) => {
  const [leadDetails, setLeadDetails] = useState({
    modal: false,
    edit: false,
    preview: false,
    data: "",
  });
  const [showExpertAssign, setShowExpertAssign] = useState("");
  const [clientId, setClientId] = useState("");
  const [expertId, setExpertId] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState(["ALL_LEADS"]);

  // State for Sorting
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });

  const { user } = useSelector((state) => state.authenticationReducer);
  const { isListLoading } = useSelector((state) => state.managerReducer);
  const dispatch = useDispatch();

  const afterSave = () => {
    setLeadDetails({});
    getList({ currentPage, filter, isEmptylist: false, sortConfig });
  };

  const onPagination = (currentPage) => {
    getList({ currentPage, filter, sortConfig });
    setCurrentPage(currentPage);
  };

  const onSelectFilter = (filter) => {
    getList({ currentPage, filter, sortConfig });
  };

  // Sorting Functionality
  const onSort = (column) => {
    let direction = "asc";
    if (sortConfig.key === column && sortConfig.direction === "asc") {
      direction = "desc";
    }
    const newSortConfig = { key: column, direction };
    setSortConfig(newSortConfig);
    getList({
      currentPage,
      isEmptylist: true,
      filter,
      sortConfig: newSortConfig,
    });
  };

  const isClient = user?.userType === "CLIENT";
  const isMangerOrOperator =
    user?.userType === "OPERATOR" || user?.userType === "MANAGER";

  return (
    <Card className="h-100">
      <CustomModal
        open={showExpertAssign}
        onClose={() => setShowExpertAssign("")}
        content={() => (
          <AssignExpert
            openLead={showExpertAssign}
            onClose={() => {
              setShowExpertAssign("");
              afterSave();
            }}
          />
        )}
      />

      <FullScreenModal
        title={"Personal Details"}
        bgContent="dark-bg"
        size="xl"
        open={clientId}
        onClose={() => setClientId("")}
        content={() => (
          <PersonalCard
            hideAction={true}
            onDeleteClick={(data) => {}}
            onEditClick={(data) => {}}
            data={clientId}
            close={() => setClientId("")}
          />
        )}
      />

      <FullScreenModal
        title={"Expert Details"}
        bgContent="dark-bg"
        size="xl"
        open={expertId}
        onClose={() => setExpertId("")}
        content={() => (
          <PersonalCard
            hideAction={true}
            onDeleteClick={(data) => {}}
            onEditClick={(data) => {}}
            data={expertId}
            close={() => setExpertId("")}
          />
        )}
      />

      <CustomModal
        title={addButtonTitle}
        bgContent="dark-bg"
        open={leadDetails.modal}
        onClose={() => setLeadDetails({})}
        content={() => (
          <FormEditLeadGenerate data={leadDetails?.data} close={afterSave} />
        )}
      />

      <Card.Header className="bg-white py-4">
        <h4 className="mb-0">
          {listTitle}{" "}
          <Buttons
            onClick={() => setLeadDetails({ modal: true, edit: false })}
            title={addButtonTitle}
            icon="plus"
          />{" "}
          <span
            className={`filter-icon ${showFilter && "applied-filter-icon"}`}
            onClick={() => setShowFilter(!showFilter)}
          >
            <Icons title={"filter"} />
          </span>
          {isMangerOrOperator && (
            <RangeDateFilter getList={getList} filter={filter} />
          )}
        </h4>
      </Card.Header>
      <HeaderChips
        list={LeadFilter}
        onSelectFilter={onSelectFilter}
        filter={filter}
        setFilter={setFilter}
        showFilter={showFilter}
      />
      <Table responsive className="text-nowrap">
        <thead className="table-light">
          <tr>
            <th className="action-th"></th>
            <th onClick={() => onSort("leadId")} >
              #{isClient ? "Order Id" : "Lead Id"}
              {sortConfig.key === "leadId" && (
                <Icons
                  size={30}
                  color="black"
                  title={
                    sortConfig.direction === "asc" ? "arrow-up" : "arrow-down"
                  }
                />
              )}
            </th>
            <th onClick={() => onSort("name")}>
              Name
              {sortConfig.key === "name" && (
                <Icons
                  size={30}
                  color="black"
                  title={
                    sortConfig.direction === "asc" ? "arrow-up" : "arrow-down"
                  }
                />
              )}
            </th>
            <th onClick={() => onSort("subject")}>
              Subject
              {sortConfig.key === "subject" && (
                <Icons
                  size={30}
                  color="black"
                  title={
                    sortConfig.direction === "asc" ? "arrow-up" : "arrow-down"
                  }
                />
              )}
            </th>
            <th onClick={() => onSort("status")}>
              Status
              {sortConfig.key === "status" && (
                <Icons
                  size={30}
                  color="black"
                  title={
                    sortConfig.direction === "asc" ? "arrow-up" : "arrow-down"
                  }
                />
              )}
            </th>
            <th onClick={() => onSort("clientDeadline")}>
              Client Deadline
              {sortConfig.key === "clientDeadline" && (
                <Icons
                  size={30}
                  color="black"
                  title={
                    sortConfig.direction === "asc" ? "arrow-up" : "arrow-down"
                  }
                />
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {TeamsData?.data?.map((item, index) => {
            return (
              <tr key={index}>
                <td className="action-td">
                  <LeadActionButtons
                    setShowExpertAssign={setShowExpertAssign}
                    afterSave={afterSave}
                    lead={item}
                  />
                </td>
                <td
                  className="align-middle pointer link"
                  onClick={() =>
                    dispatch(
                      startChatAction({ leadDetail: item, userId: user?._id })
                    )
                  }
                >
                  {isClient ? item?.orderId || item.leadId : item.leadId}
                </td>
                <td
                  className="align-middle pointer link"
                  onClick={() => setClientId(item?.client)}
                >
                  {item?.client?.name}
                </td>
                <td
                  className="align-middle"
                  style={{ maxWidth: "150px", overflow: "hidden" }}
                >
                  {item?.subject}
                </td>
                <td className="align-middle ">
                  {isClient
                    ? item?.orderStatus || item?.leadStatus
                    : item?.leadStatus}
                </td>
                <td className="align-middle">
                  {item?.clientDeadline &&
                    returnDateWithTime(item?.clientDeadline)}
                </td>
              </tr>
            );
          })}
          {isListLoading && (
            <tr>
              <td colSpan={6}>
                <OperatorSkeleton />
              </td>
            </tr>
          )}
          <tr>
            <td colSpan={6}>
              {TeamsData?.pagination && (
                <Paginations
                  onChange={onPagination}
                  pagination={TeamsData?.pagination}
                />
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    </Card>
  );
};

export default LeadListComponent;
