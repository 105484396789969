import React, { Fragment, useEffect, useState } from 'react'
import socket from '../../socket/socket';

const GetVisitorDetails = () => {
    const [currentData, setCurrentData] = useState({
        website: "",
        page: "",
      });
    
      // Function to get the user's IP address
      const getIp = async () => {
        const response = await fetch("https://api64.ipify.org?format=json"); // Use ipify's IPv6-compatible endpoint
        const data = await response.json();
        return data.ip;
      };
    
      // Extract query parameters when component mounts
      useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const websiteParam = queryParams.get("website");
        const currentPage = queryParams.get("currentPage");
        const refralUrl = queryParams.get("redirect");
    
        setCurrentData({
          website: websiteParam,
          currentPage: currentPage,
          refralUrl,
        });
      }, []);
    
      // Fetch IP address and send socket event when `currentData` is updated
      useEffect(() => {
        const sendSocketEvent = async () => {
          const ip = await getIp();
          if (currentData?.website && ip) {
            socket.emit("live_user_with_page", { ...currentData, ip });
            return () => {
              socket.off("all_live_user");
            };
          }
        };
    
        sendSocketEvent();
      }, [currentData]);
    
      useEffect(() => {
        const handleVisibilityChange = async () => {
          if (document.visibilityState === "hidden") {
            socket.emit("disconnect_user");
          } else if (document.visibilityState === "visible") {
            const queryParams = new URLSearchParams(window.location.search);
            const websiteParam = queryParams.get("website");
            const currentPage = queryParams.get("currentPage");
            const refralUrl = queryParams.get("redirect");
            const ip = await getIp();
            socket.emit("live_user_with_page", {
              website: websiteParam,
              currentPage,
              refralUrl,
              ip,
            });
          }
        };
    
        const handleBeforeUnload = () => {
          socket.emit("disconnect_user");
        };
    
        window.addEventListener("beforeunload", handleBeforeUnload);
        document.addEventListener("visibilitychange", handleVisibilityChange);
    
        return () => {
          window.removeEventListener("beforeunload", handleBeforeUnload);
          document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
      }, []);
    
  return (
    <Fragment>

    </Fragment>
  )
}

export default GetVisitorDetails