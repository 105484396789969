import React, { useState, useEffect, useRef } from "react";
import LiverUserFilterChips from "./LiverUserFilterChips";

const LiveUserSelectWebsite = ({filter,getAllLiveUser,setFilter}) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);
  const websites = ["all website","assignmentwriter.io", "clickinpedia.io","myassignmethelp.io"];

  useEffect(() => {
    const handleClickOutside = (e) => dropdownRef.current && !dropdownRef.current.contains(e.target) && setOpen(false);
    open && document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [open]);

  return (
    <span onClick={() => setOpen(!open)}>
      <LiverUserFilterChips className="ml-3 mt-2" label={filter?.website} backgroundColor="#e74c34" isFilled={true}/>
      {open && (
        <div ref={dropdownRef} className="show-website-dropdown pointer">
          {websites.map((site,index) => (
            <p key={site} className={`web-title ${index==0 ?"mt-2":""}`} onClick={() => { 
                 getAllLiveUser({tempWebsite:site})
                 setFilter((prev)=>({...prev,website:site}))
                 setOpen(false); }}>
              {site}
            </p>
          ))}
        </div>
      )}
    </span>
  );
};

export default LiveUserSelectWebsite;
