import { Fragment, useState } from "react";
import CustomDatePicker from "../../../utils/date-picker/CustomDatePicker";
import Buttons from "../../../utils/buttons/Buttons";
import CustomModal from "../../../utils/modals/CustomModal";
import Icons from "../../../utils/Icons";

const DateRangePicker = ({
  label,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  onSelect,
}) => (
  <div className="row">
    <div className="col-sm-6">
      <label>{label} (From)</label>
      <CustomDatePicker
        value={startDate}
        setValue={(date) => {
          setStartDate(date);
          onSelect(label);
        }}
      />
    </div>
    <div className="col-sm-6">
      <label>{label} (To)</label>
      <CustomDatePicker
        value={endDate}
        setValue={setEndDate}
        minDate={startDate} // Disables earlier dates in "To" field
      />
    </div>
  </div>
);

const RangeDateFilter = ({ getList, filter }) => {
  const [clientStartDate, setClientStartDate] = useState(null);
  const [clientEndDate, setClientEndDate] = useState(null);
  const [expertStartDate, setExpertStartDate] = useState(null);
  const [expertEndDate, setExpertEndDate] = useState(null);
  const [leadStartDate, setLeadStartDate] = useState(null);
  const [leadEndDate, setLeadEndDate] = useState(null);
  const [isDateFilter, setIsDateFilter] = useState(false);

  const handleDateRangeSelect = (selectedRange) => {
    if (selectedRange !== "Client Deadline") {
      setClientStartDate(null);
      setClientEndDate(null);
    }
    if (selectedRange !== "Expert Deadline") {
      setExpertStartDate(null);
      setExpertEndDate(null);
    }
    if (selectedRange !== "Lead Registration") {
      setLeadStartDate(null);
      setLeadEndDate(null);
    }
  };

  const clearFilter = () => {
    setClientStartDate(null);
    setClientEndDate(null);
    setExpertStartDate(null);
    setExpertEndDate(null);
    setLeadStartDate(null);
    setLeadEndDate(null);
    getList({
      currentPage: 1,
      filter,
      isEmptylist: true,
    });
    setIsDateFilter(false);
  };

  const validateDateRanges = () => {
    const isClientFilled = clientStartDate || clientEndDate;
    const isExpertFilled = expertStartDate || expertEndDate;
    const isLeadFilled = leadStartDate || leadEndDate;

    if (
      (isClientFilled && (isExpertFilled || isLeadFilled)) ||
      (isExpertFilled && (isClientFilled || isLeadFilled)) ||
      (isLeadFilled && (isClientFilled || isExpertFilled))
    ) {
      alert("Please fill in only one date range at a time.");
      return false;
    }
    return true;
  };

  const applyFilter = () => {
    if (!validateDateRanges()) return;

    getList({
      currentPage: 1,
      filter,
      dateFilter: {
        clientDeadline: { startDate: clientStartDate, endDate: clientEndDate },
        expertDeadline: { startDate: expertStartDate, endDate: expertEndDate },
        leadRegistration: { startDate: leadStartDate, endDate: leadEndDate },
      },
      isEmptylist: true,
    });
    setIsDateFilter(false);
  };

  const Content = () => (
    <Fragment>
      <div className="form">
        <DateRangePicker
          label="Client Deadline"
          startDate={clientStartDate}
          setStartDate={setClientStartDate}
          endDate={clientEndDate}
          setEndDate={setClientEndDate}
          onSelect={handleDateRangeSelect}
        />
        <div className="col-md-12 text-center">
          <span>Or</span>
        </div>
        <DateRangePicker
          label="Expert Deadline"
          startDate={expertStartDate}
          setStartDate={setExpertStartDate}
          endDate={expertEndDate}
          setEndDate={setExpertEndDate}
          onSelect={handleDateRangeSelect}
        />
        <div className="col-md-12 text-center">
          <span>Or</span>
        </div>
        <DateRangePicker
          label="Lead Registration"
          startDate={leadStartDate}
          setStartDate={setLeadStartDate}
          endDate={leadEndDate}
          setEndDate={setLeadEndDate}
          onSelect={handleDateRangeSelect}
        />
        <div className="col-md-12 text-center" style={{ marginTop: 20 }}>
          <Buttons title="Apply Filter" onClick={applyFilter} />
          <button className="btn btn-sm btn-danger ml-1" onClick={clearFilter}>
            Clear Filter
          </button>
        </div>
      </div>
    </Fragment>
  );

  return (
    <Fragment>
      <CustomModal
        onClose={() => setIsDateFilter(false)}
        size="md"
        open={isDateFilter}
        content={Content}
      />
      <span
        className="filter-icon"
        onClick={() => setIsDateFilter(!isDateFilter)}
      >
        <Icons color="#00234a" title="date-filter" />
      </span>
    </Fragment>
  );
};

export default RangeDateFilter;
