import React from "react";
import { useDispatch } from "react-redux";
import { logoutAction } from "../../services/action/authentication";
import { useNavigate } from "react-router-dom";
import { closeChatAction } from "../../services/action/common";

const Logout = ({ child }) => {
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const logout = () => {
    dispatch(logoutAction());
    dispatch(closeChatAction())
    navigate("/")
  };

  return <span onClick={logout}>{child}</span>;
};

export default Logout;
