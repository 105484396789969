import { useDispatch, useSelector } from "react-redux";
import { uploadImageAction } from "../../../services/action/common";
import Icons from "../../utils/Icons";
import { useRef } from "react";

const DocumentButton = ({sendMessage}) => {
   const dispatch=useDispatch();
   const fileRef = useRef();
   const { isImageLoading } = useSelector((state) => state.commonReducer);

    const uploadImage = (e) => {
    const callBackImage = (res) => {
    let messageFile = "";
    res?.imageUrls?.map((item) => {
      messageFile =
        messageFile +
        `<a target='_blank' href=${item}><i class="fa fa-file" aria-hidden="true"></i></a>`;
    });
    const message = `<div class="delivery-chat-box"><p class="file-list">${messageFile} </p></div>`;
    sendMessage(
      message
    );
  };
    let formData = new FormData();
    for (let i = 0; i < e.target.files.length; i++) {
      formData.append("images", e.target.files[i]);
    }
    dispatch(uploadImageAction({ data: formData, cb: callBackImage }));
    e.target.value = null;
  };

  
  return (
    <>
      <input
        type="file"
        onChange={uploadImage}
        className="display-none"
        ref={fileRef}
        multiple={true}
      />
      <Icons
        onClick={()=>fileRef.current?.click()}
        title={isImageLoading?"loader":"file"}
        className="pointer attach-file"
        color="#fff"
        size={20}
      />
    </>
  );
};
export default DocumentButton;
