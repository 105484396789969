import {   takeLatest } from "redux-saga/effects";
import { sagaEngine } from "./sagaEngine";
import { appActions } from "../action";

function* mySaga() {
  yield takeLatest(appActions.LOGIN, sagaEngine);
  yield takeLatest(appActions.GET_USER,sagaEngine)
  yield takeLatest(appActions.GET_ALL_USER_ACTION,sagaEngine)
  yield takeLatest(appActions.CREATE_USER,sagaEngine)
  yield takeLatest(appActions.DELETE_USER_MANAGER,sagaEngine)
  yield takeLatest(appActions.UPDATE_USER_MANAGER,sagaEngine)
  yield takeLatest(appActions.UPLOAD_IMAGE,sagaEngine)
  yield takeLatest(appActions.GET_CLIENT_LIST_OPTION_MANAGER,sagaEngine)
  yield takeLatest(appActions.GENERATE_LEAD_MANAGER,sagaEngine)
  yield takeLatest(appActions.GET_GENERATE_LEAD_ADMIN,sagaEngine)
  yield takeLatest(appActions.UPDATE_LEAD_MANAGER,sagaEngine)
  yield takeLatest(appActions.START_CHAT,sagaEngine)
  yield takeLatest(appActions.GET_CHAT,sagaEngine)
  yield takeLatest(appActions.GET_DASHBOARD,sagaEngine)
  yield takeLatest(appActions.GET_EXPERT_LIST_OPTION, sagaEngine)
  yield takeLatest(appActions.GET_NOTIFICATION_LIST,sagaEngine)
  yield takeLatest(appActions.UPDATE_NOTIFICATION_STATUS,sagaEngine)
  yield takeLatest(appActions.GENERATE_PAYMENT_LINK,sagaEngine)
  yield takeLatest(appActions.GET_COUNTRY_LIST,sagaEngine)
  yield takeLatest(appActions.UPLOAD_AUDIO,sagaEngine)
  yield takeLatest(appActions.CHANGE_CHAT_VISIBITY,sagaEngine)
  yield takeLatest(appActions.RESET_PASSWORD,sagaEngine)
  yield takeLatest(appActions.UPDATE_PASSWORD,sagaEngine)
  yield takeLatest(appActions.SENT_PAYMENT_LINK_WHATSAPP,sagaEngine)
  yield takeLatest(appActions.CHANGE_CALL_HIPPO_PERMISSION,sagaEngine)
  yield takeLatest(appActions.GET_OPERATOR_MANAGER_LIST_ACTION,sagaEngine)
  yield takeLatest(appActions.ORDER_DELIVERED_ACTION, sagaEngine)
  yield takeLatest(appActions.SEND_BALANCED_AMOUNT_TO_CLIENT,sagaEngine)
  yield takeLatest(appActions.REQUEST_FOR_ASSIGN_LEAD,sagaEngine)
  yield takeLatest(appActions.GET_ALL_LIVE_USER_LIST,sagaEngine)
  yield takeLatest(appActions.GET_OPEN_USER_CHAT_LIST,sagaEngine)
  yield takeLatest(appActions.GET_OPEN_NOTIFICATION,sagaEngine)
}

export default mySaga;
