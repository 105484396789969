import React, { useEffect } from "react";
import LeadListComponent from "../../components/manager/leads/LeadListComponent";
import DashboardLayout from "../../components/layouts/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { getLeadsAction } from "../../services/action/manager";
const LeadListOperator = () => {
  const dispatch = useDispatch();
  const { leads } = useSelector((state) => state.managerReducer);

  const getList = ({
    currentPage,
    isEmptylist = true,
    search = "",
    dateFilter,
    filter = ["ALL_LEADS"],
    sortConfig
  }) => {
    dispatch(
      getLeadsAction({
        userType:"operator",
        data: { sortConfig,search, page: currentPage, isEmptylist, leadStatus: filter,dateFilter },
      })
    );
  };
  useEffect(() => {
    getList({ currentPage: 1 });
  }, []);
  return (
    <DashboardLayout onSearch={(value)=>getList({search:value})}>
      <LeadListComponent getList={getList} TeamsData={leads} />
    </DashboardLayout>
  );
};

export default LeadListOperator;
