import React, { useEffect } from "react";
import ExpertListComponent from "../../components/manager/users/experts/ExpertListComponent";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsersAction } from "../../services/action/manager";
import DashboardLayout from "../../components/layouts/DashboardLayout";
const ExpertListSA = () => {
  const dispatch = useDispatch();
  const { userList } = useSelector((state) => state.managerReducer);

  const getList = ({ currentPage, keyword = "", isEmptylist = true }) => {
    dispatch(
      getAllUsersAction({
        userType:"super_admin",
        data: { userType: "EXPERT", page: currentPage, isEmptylist, keyword },
      })
    );
  };
  useEffect(() => {
    getList({ isEmptylist: 1 });
  }, []);
  return (
    <DashboardLayout
      onSearch={(e) =>
        getList({ currentPage: 1, isEmptylist: false, keyword: e })
      }
    >
      <ExpertListComponent getList={getList} TeamsData={userList} />
    </DashboardLayout>
  );
};

export default ExpertListSA;
