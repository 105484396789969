import React, { useEffect, useRef, useState } from "react";
import Icons from "../utils/Icons";
import { useDispatch, useSelector } from "react-redux";
import socket from "../socket/socket";
import {
  closeChatAction,
  getChatListAction,
  getNotificationListAction,
} from "../../services/action/common";
import {
  returnDateWithTime,
  returnFirstDigit,
  sanitizeMessage,
  truncateString,
} from "../utils/functions/functions";
import EmojiButton from "./chat-component/EmojiButton";
import DocumentButton from "./chat-component/DocumentButton";
import VoiceButton from "./chat-component/VoiceButton";
import PaymentButton from "./chat-component/PaymentButton";
import EditableMessage from "./chat-component/EditableMessage";
import AddMoreOption from "./chat-component/AddMoreOption";
import LeadDetailCard from "./chat-component/LeadDetailCard";

const ChatBox = () => {
  const [isMinimize, setIsMinimize] = useState(true);
  const [maximize, setMaximize] = useState(false);
  const [inputMessage, setInputMessage] = useState("");
  const { user } = useSelector((state) => state.authenticationReducer);
  const { leadDetail } = useSelector((state) => state.commonReducer);
  const { chatList } = useSelector((state) => state.managerReducer);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [recordingStart, setRecordingStart] = useState(false);
  const [showNoticeMessage,setShowNoticeMessage]=useState(false)
  const [isReRender, setIsReRender] = useState("");
  const [messageId, setMessageId] = useState({
    messageId: "",
    oldMessage: "",
    currentIndex: -1,
  });

  let userType = user?.userType?.toLowerCase();
  const [isDetailLead, setIsDetailLead] = useState(false);

  useEffect(() => {
    if (isReRender) {
      dispatch(
        getChatListAction({
          userType,
          leadId: isReRender,
          cb: () => setIsReRender(""),
        })
      );
      dispatch(getNotificationListAction({ userType }));
    }
  }, [isReRender]);

  useEffect(() => {
    socket.on("refresh_lead", ({ leadId }) => {
      setIsReRender(leadId);
    });
    return () => {
      socket.off("refresh_lead");
    };
  }, []);

  useEffect(() => {
    socket.on(
      "current_message",
      ({ sendTime, leadId, senderId, sender, message, messageId }) => {
        setData((prevData) => [
          ...prevData,
          {
            leadId,
            senderId,
            time: sendTime,
            sender,
            message,
            _id: messageId,
          },
        ]);
      }
    );
    return () => {
      socket.off("current_message");
    };
  }, []);

  // Reference for chat box
  const chatBoxRef = useRef(null);

  useEffect(() => {
    socket.on(
      "private_message",
      ({ messageId, senderId, sendTime, leadId, senderName, message }) => {
        setData((prevData) => [
          ...prevData,
          {
            _id: messageId,
            leadId,
            senderId,
            time: sendTime,
            sender: senderName,
            message,
          },
        ]);
      }
    );
    return () => {
      socket.off("private_message");
    };
  }, []);

  useEffect(() => {
    if (chatList?.data?.sockets) {
      setData(chatList?.data?.sockets);
    }
  }, [chatList]);

  useEffect(() => {
    if (leadDetail?._id) {
      setIsMinimize(false);
      dispatch(getChatListAction({ userType, leadId: leadDetail?._id }));
    }
  }, [leadDetail, dispatch, userType]);

  useEffect(() => {
    // Scroll to the bottom of the chat box whenever new data is added
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [data]);

  const updateMessage = (msg) => {
    msg = sanitizeMessage(msg || inputMessage, messageId);
    socket.emit("update_message", {
      newMessage: msg,
      messageId: messageId?.messageId,
      oldMessage: messageId?.oldMessage,
      leadId: leadDetail?._id,
      editedBy: user?._id,
      editTime: new Date(),
    });
    setData((prevData) => {
      return prevData.map((item, index) =>
        index === messageId?.currentIndex ? { ...item, message: msg } : item
      );
    });
    setMessageId({});
    setInputMessage("");
  };

  const sendMessage = (msg, isMasking, onlyFor = ["ALL"]) => {
    if (!msg && !inputMessage) {
      return false;
    }
    if (messageId?.messageId) {
      updateMessage(msg || inputMessage);
      return false;
    }
    socket.emit("private_message", {
      message: msg || inputMessage,
      leadId: leadDetail?._id,
      senderId: user?._id,
      senderName: user?.name,
      subject: leadDetail?.subject,
      isMasking,
      onlyFor,
      sendTime: new Date(),
    });

    setInputMessage("");
  };

  useEffect(() => {
    if (user?._id) {
      socket.emit("initiate_chat", {
        userId: user?._id,
      });
    }
  }, [user?._id]);

  return (
    leadDetail?.subject && (
      <div>
        <div
          className={`custom-chat-area ${maximize ? "maximum-chat-area" : ""}`}
          style={{
            minHeight: isMinimize ? "0px" : maximize ? "97vh" : "300px",
          }}
        >
          <div className="row m-0">
            <div className="col-12 project-header flex">
              <h4 className="text-white">
                #{leadDetail?.orderId || leadDetail?.leadId}
                <span onClick={()=>setIsDetailLead(!isDetailLead)}>
                  <Icons className={"pointer"} title={"down-arrow"} />
                </span>
              </h4>
              <span>
                <button
                  className="relative top-4 left-4 text-white mr-1"
                  onClick={() => setIsMinimize(!isMinimize)}
                >
                  <Icons
                    title={isMinimize ? "start" : "minus"}
                    style={{ marginTop: "3px" }}
                  />
                </button>
                <button
                  className="relative top-4 left-4 text-white mr-2 ml-2"
                  onClick={() => setMaximize(!maximize)}
                >
                  <Icons title={"maxmize"} color="#fff" />
                </button>
                <button
                  className="relative top-4 left-4 text-white mr-21"
                  onClick={() => dispatch(closeChatAction())}
                >
                  <Icons title={"cross"} />
                </button>
              </span>
            </div>
          </div>
          <div className={`row m-0 chat-body ${isMinimize && "hide-chat"}`}>
            {isDetailLead && <LeadDetailCard leadDetails={leadDetail}/>}
            <div className="col-12 chat-box" ref={chatBoxRef}>
              {data.map((item, index) => (
                <div
                  className={` ${user?._id == item?.senderId && "sender-me"}`}
                  key={item?.senderId + "-" + index}
                >
                  <div className="message-box-area">
                    <EditableMessage
                      currentIndex={index}
                      setMessageId={setMessageId}
                      setInputMessage={setInputMessage}
                      currentData={item}
                      leadId={leadDetail?._id}
                      setData={setData}
                    />
                    <span className="sender-name">
                      <span className="sender-details">
                        <span className="name">
                          {truncateString(item.sender)}{" "}
                          <span className="user-type">
                            ({item?.userType || user?.userType})
                          </span>{" "}
                        </span>
                      </span>
                      <span className="time">
                        {returnDateWithTime(item.time)}
                      </span>
                    </span>
                  </div>
                </div>
              ))}
            </div>

            {showNoticeMessage && <div className="notice-message-chat">
                <p>Dear All, You are requested not to share any contact details here in the chat. Please connect with operators to make a call or schedule a meeting.</p>
              </div>}
            <div className="col-12 chat-footer">
        
              <div className="inner-footer">
                {!recordingStart && (
                  <>
                    <EmojiButton sendMessage={sendMessage} />
                    <DocumentButton sendMessage={sendMessage} />
                    <input
                      value={inputMessage}
                      onChange={(e) => {
                        setInputMessage(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && inputMessage) {
                          e.preventDefault();
                          sendMessage();
                        }
                      }}
                      onFocus={()=>setShowNoticeMessage(true)}
                      onBlur={()=>setShowNoticeMessage(false)}
                      type="text"
                      className="chat-input"
                      placeholder="Message..."
                    />
                    <span
                      onClick={() => sendMessage()}
                      className="send-button margin-right-0"
                      aria-label="Send"
                    >
                      <Icons title="send-icon" size={26} color="#fff" />
                    </span>
                  </>
                )}
                {recordingStart && (
                  <img
                    src="/assets/images/recording.gif"
                    className="wave-recording"
                  />
                )}

                <VoiceButton
                  setRecordingStart={setRecordingStart}
                  sendMessage={sendMessage}
                />
                <PaymentButton
                  sendMessage={sendMessage}
                  leadId={leadDetail?._id}
                />
                <AddMoreOption sendMessage={sendMessage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ChatBox;
