
import './App.css';
import CustomRouter from './router/router';
function App() {
  return (
    <CustomRouter />
  );
}

export default App;
