import React from "react";
import Chips from "../../../utils/chips/Chips";

const HeaderChips = ({list,onSelectFilter, showFilter, filter, setFilter }) => {
 
  return (
    <ul className={`chip-row ${showFilter && "show"}`}>
      {list?.map((item,index) => (
        <li
          key={index}
          onClick={() => {
            if (filter?.includes(item.value)) {
              let filters = filter?.filter((itm) => item?.value != itm);
              setFilter(filters);
              onSelectFilter(filters)
            } else {
              setFilter([...filter, item.value]);
              onSelectFilter([...filter, item.value])
            }
          }}
          className={`chip-child ${filter?.includes(item.value) && "active"}`}
        >
          <Chips title={item.label} />
        </li>
      ))}
    </ul>
  );
};

export default HeaderChips;
