// import node module libraries
import Link from 'next/link';
import {  Col, Row, Card, Table } from 'react-bootstrap';
import { returnDateWithTime } from './functions/functions';
import { useSelector } from 'react-redux';

// import required data files

const LeadListDashboard = ({ data, pageTitile = "Lead" }) => {
    const { user } = useSelector((state) => state.authenticationReducer)
    const userType = user?.userType?.toLowerCase()
    const isManagerOrOperator = user?.userType === "MANAGER" || user?.userType === "OPERATOR";
    const isClient = user?.userType === "CLIENT";
    const isExpert = user?.userType === "EXPERT";
    return   (
        <Row className="mt-6">
            <Col md={12} xs={12}>
                <Card>
                    <Card.Header className="bg-white  py-4">
                        <h4 className="mb-0">Active {pageTitile + "s"}</h4>
                    </Card.Header>
                    <Table responsive className="text-nowrap mb-0">
                        <thead className="table-light">
                            <tr>
                                <th>{pageTitile} Id</th>
                                <th>{pageTitile} Name</th>
                                <th>Status</th>
                                <th>{isClient ? "" : "Client"} Deadline</th>
                                {isManagerOrOperator && <th>Expert Deadline</th>}
                                {isManagerOrOperator && <th>Reg. Date</th>}

                            </tr>
                        </thead>
                        <tbody>
                            {data?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="align-middle">
                                            {item?.leadId}
                                        </td>
                                        <td className="align-middle">{item.subject}</td>
                                        <td className="align-middle">{item?.leadStatus || item?.orderStatus}</td>
                                        <td className="align-middle">
                                            {returnDateWithTime(isExpert ? item?.expertDeadline : item?.clientDeadline)}
                                        </td>
                                        {isManagerOrOperator && <td className="align-middle">{returnDateWithTime(item?.expertDeadline)}</td>}
                                        {isManagerOrOperator && <td className="align-middle text-dark">
                                            {returnDateWithTime(item?.createdAt)}
                                        </td>}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                    <Card.Footer className="bg-white text-center">
                        <Link href={`/${userType=="super_admin"?"super-admin":userType}/${pageTitile === "Lead" ? "leads" : "orders"}`} className="link-primary">View All {pageTitile}s</Link>
                    </Card.Footer>
                </Card>
            </Col>
        </Row>
    )
}

export default LeadListDashboard