import DashboardLayout from "./../../components/layouts/DashboardLayout";
import LiveUserTrack from "../../components/live-users/LiveUserTrack";

const LiveUser = () => {
  return (
    <DashboardLayout>
        <LiveUserTrack />
    </DashboardLayout>
  );
};
export default LiveUser;
