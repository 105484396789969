import * as React from 'react';
import Chip from '@mui/material/Chip';
export default function ChipsDeletable({title,onClick,onDelete}) {
  return (
      <Chip
        label={title}
        variant="outlined"
        style={{color:"blue"}}
        onClick={onClick}
        onDelete={onDelete}
        size="small"
      />
  );
}
