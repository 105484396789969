const Col6 = (title, value) => {
    if (title === "Payment Screenshort") {
     return value && <div className="col-sm-6">
        <p className="text-sm text-gray-600">
          <b>{title}</b>
        </p>
        {
          Array.isArray(value) && value?.map((item,index)=>(
            <p key={index}>
            <a target="_blank" href={item} rel="noreferrer"> Link {index+1}</a>
          </p>
          ))
        }
      </div>;
    } else if (title === "Requirement File") {
       return !!value&&  <div className="col-sm-6">
        <p className="text-sm text-gray-600">
          <b>{title}</b>
        </p>
        {
          Array.isArray(value) && value?.map((item,index)=>(
            <p key={index}>
            <a target="_blank" href={item} rel="noreferrer"> Link {index+1}</a>
          </p>
          ))
        }
     
      </div>;
    } else {
      return (
        !!value && (
          <div className="col-sm-6">
            <p className="text-sm text-gray-600">
              <b>{title}</b>: {value}
            </p>
          </div>
        )
      );
    }
  };

  export default Col6;