import React, { Fragment, useState } from "react";
import CustomModal from "../../../../utils/modals/CustomModal";
import ExpertListOption from "./ExpertListOption";
const ExpertRequestOption = ({ lead,expertRequest,afterSave }) => {
  const [showList,setShowList]=useState(false)

  const closeModal=()=>{
    afterSave()
    setShowList(false)
  }
  return (
    <Fragment>
      <CustomModal 
        title={"Request List"}
        size="xl"
        open={showList}
        onClose={()=>setShowList(false)}
        content={()=><ExpertListOption lead={lead} expertRequest={expertRequest} afterSave={closeModal}/>}
      />
      <span className="link pointer"
       onClick={()=>{
        if(expertRequest?.length>0){
          setShowList(true)
        }
       }}
      >
        Request({expertRequest?.length || 0})
      </span>
    </Fragment>
  );
};

export default ExpertRequestOption;
