import { useEffect } from "react";

const ShowCallHippo = () => {
  useEffect(() => {
    const loadCallHippoScript = () => {
      return new Promise((resolve, reject) => {
        window.TOKEN = "66bf040b4e303d7d9f18c42d";
        window.EMAIL = "gopal@clickinpedia.com";
        window.REGION = "global";

        if (!document.getElementById("ch-dialer-container")) {
          const chWidgetDiv = document.createElement("div");
          chWidgetDiv.id = "ch-dialer-container";
          document.body.appendChild(chWidgetDiv);
        }

        if (!document.getElementById("callhippo-script")) {
          const callhippoScript = document.createElement("script");
          callhippoScript.id = "callhippo-script";
          callhippoScript.type = "text/javascript";
          callhippoScript.async = true;
          callhippoScript.src =
            "https://d1x9dsge91xf6g.cloudfront.net/callhippo/files/ch-dialer.js";
          document.body.appendChild(callhippoScript);

          callhippoScript.onload = () => {
            console.log("CallHippo script loaded successfully.");
            resolve();
          };

          callhippoScript.onerror = () => {
            console.error("Failed to load the CallHippo script.");
            reject();
          };
        } else {
          resolve();
        }
      });
    };

    const initializeCallHippo = async () => {
      try {
        await loadCallHippoScript();

        const style = document.createElement("style");
        style.innerHTML = `
          #ch-dialer-container .ch-open-button {
            left: 14px !important;
            background-color: transparent;
            border-color: white;
          }
          #ch-dialer-container #chCallMePopup {
            left: 10px;
            max-width: 370px;
          }
        `;
        document.head.appendChild(style);

        const iframe = document.getElementById("chFrame");

        // Listen for messages from the iframe
        window.addEventListener("message", (event) => {
          if (event.origin === "https://d1x9dsge91xf6g.cloudfront.net") {
            if (event.data.type === "callLogNameOrNum") {
              console.log("Call log name or number:", event.data.value);
            }
          }
        });

        const callIcon = document.getElementById("ch-call-icn");
        if (callIcon) {
          callIcon.addEventListener("click", () => {
            if (iframe && iframe.contentWindow) {
              iframe.contentWindow.postMessage(
                { action: "getCallLogNameOrNum" },
                "https://d1x9dsge91xf6g.cloudfront.net"
              );
            } else {
              console.log("Iframe or contentWindow not found");
            }
          });
        } else {
          console.log("Call icon element not found");
        }
      } catch (error) {
        console.error("Error initializing CallHippo:", error);
      }
    };

    if (document.readyState === "loading") {
      document.addEventListener("DOMContentLoaded", initializeCallHippo);
    } else {
      initializeCallHippo();
    }

    return () => {
      document.removeEventListener("DOMContentLoaded", initializeCallHippo);
    };
  }, []);

  return null;
};

export default ShowCallHippo;
