// import node module libraries
import React, { Fragment, useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import CustomModal from "../../utils/modals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import FormEditLeadGenerate from "./components/FormEditLeadGenerate";
import HeaderChips from "./components/HeaderChips";
import Icons from "../../utils/Icons";
import {
  isMissedExpertDeadline,
  returnDateWithTime,
  truncateString,
} from "../../utils/functions/functions";
import Paginations from "../../utils/pagination/Paginations";
import FullScreenModal from "../../utils/modals/FullScreenModal";
import PersonalCard from "../../card/PersonalCard";
import LeadCard from "../../card/LeadCard";
import { OrdertFilter, OrdertFilterExpert } from "../../utils/TeamsData";
import {
  requestForAssignLeadAction,
  startChatAction,
} from "../../../services/action/common";
import OperatorSkeleton from "../../skeleton/OperatorSkeleton";
import LeadActionButtons from "./LeadActionButtons";
import RangeDateFilter from "./components/RangeDateFilter";
import AssignExpert from "./components/AssignExpert";
import Chips from "../../utils/chips/Chips";
import Buttons from "../../utils/buttons/Buttons";
import RequestLeadId from "./components/expert/RequestLeadId";
import ExpertListOption from "./components/expert/ExpertRequestOption";
import { Tooltip } from "@mui/material";

const OrderListComponent = ({
  getList,
  TeamsData,
  listTitle = "Lead List",
  isOpenLeadOnly = false,
}) => {
  const [leadDetails, setLeadDetails] = useState({
    modal: false,
    edit: false,
    preview: false,
    data: "",
  });

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });
  const [showExpertAssign, setShowExpertAssign] = useState("");
  const [clientId, setClientId] = useState("");
  const [expertId, setExpertId] = useState("");
  const [leadId, setLeadId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState(["ALL_ORDERS"]);
  const { user } = useSelector((state) => state.authenticationReducer);
  const { isListLoading } = useSelector((state) => state.managerReducer);
  const { isAssignRquest } = useSelector((state) => state.commonReducer);
  const [requestLeadId, setRequestLeadId] = useState("");
  const dispatch = useDispatch();
  const isExpert = user?.userType === "EXPERT";

  const afterSave = () => {
    setLeadDetails({});
    getList({ currentPage, filter, isEmptylist: false });
  };

  const onPagination = (currentPage) => {
    getList({ currentPage, filter });
    setCurrentPage(currentPage);
  };
  // Sorting Functionality
  const onSort = (column) => {
    let direction = "asc";
    if (sortConfig.key === column && sortConfig.direction === "asc") {
      direction = "desc";
    }
    const newSortConfig = { key: column, direction };
    setSortConfig(newSortConfig);
    getList({
      currentPage,
      isEmptylist: true,
      filter,
      sortConfig: newSortConfig,
    });
  };

  const onSelectFilter = (filter) => {
    getList({ currentPage, filter });
  };

  const isMangerOrOperator =
    user?.userType === "OPERATOR" ||
    user?.userType === "MANAGER" ||
    user?.userType === "ADMIN" ||
    user?.userType === "SUPER_ADMIN";
  const isAdmin=  user?.userType === "ADMIN" || user?.userType === "SUPER_ADMIN";
  const isClientOrExpert =
    user?.userType === "CLIENT" || user?.userType === "EXPERT";
  const isManager = user?.userType === "MANAGER";




  return (
    <Card className="h-100">
      <FullScreenModal
        title={"Personal Details"}
        bgContent="dark-bg"
        size="xl"
        open={clientId}
        onClose={() => setClientId("")}
        content={() => (
          <PersonalCard
            hideAction={true}
            onDeleteClick={(data) => {}}
            onEditClick={(data) => {}}
            data={clientId}
            close={() => setClientId("")}
          />
        )}
      />
      <CustomModal
        open={showExpertAssign}
        onClose={() => setShowExpertAssign("")}
        content={() => (
          <AssignExpert
            openLead={showExpertAssign}
            onClose={() => {
              setShowExpertAssign("");
              afterSave();
            }}
          />
        )}
      />

      <CustomModal
        open={requestLeadId}
        onClose={() => setRequestLeadId("")}
        headerShown={false}
        content={() => (
          <RequestLeadId
            lead={requestLeadId}
            onClose={() => {
              setRequestLeadId("");
              afterSave();
            }}
          />
        )}
      />

      <FullScreenModal
        title={"Personal Details"}
        bgContent="dark-bg"
        size="xl"
        open={expertId}
        onClose={() => setExpertId("")}
        content={() => (
          <PersonalCard
            hideAction={true}
            onDeleteClick={(data) => {}}
            onEditClick={(data) => {}}
            data={expertId}
            close={() => setExpertId("")}
          />
        )}
      />

      <FullScreenModal
        title={"Personal Details"}
        bgContent="dark-bg"
        size="xl"
        open={leadId}
        onClose={() => setLeadId("")}
        content={() => (
          <LeadCard
            hideAction={true}
            onDeleteClick={(data) => {}}
            onEditClick={(data) =>
              setLeadDetails({ edit: true, data, modal: true })
            }
            data={leadId}
            close={() => setLeadId("")}
          />
        )}
      />
      <CustomModal
        title={"Generate Lead"}
        bgContent="dark-bg"
        open={leadDetails.modal}
        onClose={() => setLeadDetails({})}
        content={() => (
          <FormEditLeadGenerate
            page="order"
            data={leadDetails?.data}
            close={afterSave}
          />
        )}
      />

      <Card.Header className="bg-white py-4">
        <h4 className="mb-0">
          {listTitle}{" "}
          {!isOpenLeadOnly && (
            <span
              className={`filter-icon ${showFilter && "applied-filter-icon"}`}
              onClick={() => setShowFilter(!showFilter)}
            >
              <Icons title={"filter"} />
            </span>
          )}
          {isMangerOrOperator && (
            <RangeDateFilter getList={getList} filter={filter} />
          )}
        </h4>
      </Card.Header>
      {!isOpenLeadOnly && (
        <HeaderChips
          list={isExpert ? OrdertFilterExpert : OrdertFilter}
          onSelectFilter={onSelectFilter}
          filter={filter}
          setFilter={setFilter}
          showFilter={showFilter}
        />
      )}
      <Table responsive className="text-nowrap">
        <thead className="table-light">
          <tr>
            <th className="action-th"></th>
            <th style={{ width: "54px" }} onClick={() => onSort("orderId")}>
              #Order Id
              {sortConfig.key === "orderId" && (
                <Icons
                  size={30}
                  color="black"
                  title={
                    sortConfig.direction === "asc" ? "arrow-up" : "arrow-down"
                  }
                />
              )}
            </th>
            {isMangerOrOperator && (
              <th onClick={() => onSort("name")}>
                Name
                {sortConfig.key === "name" && (
                  <Icons
                    size={30}
                    color="black"
                    title={
                      sortConfig.direction === "asc" ? "arrow-up" : "arrow-down"
                    }
                  />
                )}
              </th>
            )}
            <th onClick={() => onSort("subject")}>
              Subject
              {sortConfig.key === "subject" && (
                <Icons
                  size={30}
                  color="black"
                  title={
                    sortConfig.direction === "asc" ? "arrow-up" : "arrow-down"
                  }
                />
              )}
            </th>
            <th onClick={() => onSort("status")}>
              Status
              {sortConfig.key === "status" && (
                <Icons
                  size={30}
                  color="black"
                  title={
                    sortConfig.direction === "asc" ? "arrow-up" : "arrow-down"
                  }
                />
              )}
            </th>
            {isMangerOrOperator && (
              <Fragment>
                <th onClick={() => onSort("clientDeadline")}>
                  Client Deadline
                  {sortConfig.key === "clientDeadline" && (
                    <Icons
                      size={30}
                      color="black"
                      title={
                        sortConfig.direction === "asc"
                          ? "arrow-up"
                          : "arrow-down"
                      }
                    />
                  )}
                </th>
                <th onClick={() => onSort("expertDeadline")}>
                  Expert Deadline
                  {sortConfig.key === "expertDeadline" && (
                    <Icons
                      size={30}
                      color="black"
                      title={
                        sortConfig.direction === "asc"
                          ? "arrow-up"
                          : "arrow-down"
                      }
                    />
                  )}
                </th>
                {isMangerOrOperator && (
                  <th onClick={() => onSort("expert")}>
                    Expert
                    {sortConfig.key === "clientDeadline" && (
                      <Icons
                        size={30}
                        color="black"
                        title={
                          sortConfig.direction === "asc"
                            ? "arrow-up"
                            : "arrow-down"
                        }
                      />
                    )}
                  </th>
                )}
              </Fragment>
            )}
            {(isAdmin || isManager) && <th>Generate By</th>}
            {isClientOrExpert && (
              <th
                onClick={() =>
                  onSort(isExpert ? "expertDeadline" : "clientDeadline")
                }
              >
                Deadline
              </th>
            )}

            {isExpert && isOpenLeadOnly && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {TeamsData?.data?.map((item, index) => {
            return (
              <tr
                key={index}
                className={
                  isMissedExpertDeadline(
                    item?.expertDeadline,
                    item?.orderStatus
                  ) && "tr-danger"
                }
              >
                <td className="action-td">
                  {(
                    <LeadActionButtons
                      page="order"
                      afterSave={afterSave}
                      lead={item}
                      isOpenLeadOnly={isOpenLeadOnly}
                      setShowExpertAssign={setShowExpertAssign}
                    />
                  )}
                </td>
                <td
                  onClick={() => {
                    !isOpenLeadOnly &&
                      dispatch(
                        startChatAction({ leadDetail: item, userId: user?._id })
                      );
                    // setLeadId(item);
                  }}
                  className="align-middle pointer link"
                >
                  {item?.orderId || "NA"}{" "}
                </td>
                {isMangerOrOperator && (
                  <td
                    className="align-middle pointer link"
                    onClick={() => setClientId(item?.client)}
                  >
                    {item?.client?.name}
                  </td>
                )}
                <td
                  className="align-middle"
                >
                  <Tooltip title={item?.subject}>
                  {truncateString(item?.subject,12)}
                  </Tooltip>
                </td>
                <td className="align-middle " style={{ maxWidth: "185px" }}>
                  <ul className={`chip-row show custom-chip`}>
                    <li className={`chip-child ${item.orderStatus}`}>
                      <Chips
                        title={`${item.orderStatus}${
                          item.orderStatus === "DELIVERED" &&
                          item?.paymentType !== "FULL"
                            ? "(P)"
                            : ""
                        }`}
                      />
                    </li>
                  </ul>
                </td>
                <td className="align-middle">
                  {returnDateWithTime(
                    user?.userType === "EXPERT"
                      ? item?.expertDeadline
                      : item?.clientDeadline
                  )}
                </td>
                {isMangerOrOperator && (
                  <td className="align-middle">
                    {returnDateWithTime(item?.expertDeadline)}
                  </td>
                )}
                   {isMangerOrOperator && (
                  <td className="align-middle">{item?.expert?.name || 
                    <ExpertListOption lead={item} leadId={item?._id} expertRequest={item?.expertRequest} afterSave={afterSave}/>
                  }</td>
                )}
                {(isAdmin || isManager) && (
                  <td
                    onClick={() => setExpertId(item?.createdBy)}
                    className="align-middle pointer link"
                  >
                    {item?.createdBy?.name}
                  </td>
                )}
             
                {/* {isMangerOrOperator &&<td className="align-middle">
                  {(item?.expert?.name || "NA")}
                </td>} */}
                {isExpert && isOpenLeadOnly && (
                  <td className="align-middle">
                    <Buttons
                      onClick={() => setRequestLeadId(item)}
                      disabled={isAssignRquest || item?.expertRequest}
                      title={
                        item?.expertRequest
                          ? "Already Requested"
                          : "Request For Assign"
                      }
                    />
                  </td>
                )}
              </tr>
            );
          })}
          {isListLoading && (
            <tr>
              <td colSpan={isMangerOrOperator ? 7 : 5}>
                <OperatorSkeleton />
              </td>
            </tr>
          )}
          <tr>
            <td colSpan={isMangerOrOperator ? 7 : 5}>
              {TeamsData?.pagination && (
                <Paginations
                  onChange={onPagination}
                  pagination={TeamsData?.pagination}
                />
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    </Card>
  );
};

export default OrderListComponent;
