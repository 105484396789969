import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCountryListAction } from "../../../services/action/common";
import Icons from "../Icons";

const CountryDropdown = ({ disabled, value = "+91", onChange }) => {
  const [openCountryList, setOpenCountryList] = useState(false);
  const { countryListOption, isCountryListLoading } = useSelector(
    (state) => state.commonReducer
  );
  const dispatch = useDispatch();
  const [list, setList] = useState([]);

  const [search, setSearch] = useState("");

  useEffect(() => {
    if (!list?.length && openCountryList) {
      dispatch(getCountryListAction());
    }
  }, [list, dispatch, openCountryList]);

  useEffect(() => {
    if (countryListOption?.length) {
      setList(countryListOption);
    }
  }, [countryListOption]);
  return (
    <div className="country-code-dropdown">
      <div
        className="selected-value"
        onClick={() => {
          if (!disabled) {
            setOpenCountryList(!openCountryList);
          }
        }}
      >
        {value}
      </div>
      {openCountryList && (
        <div className="drop-down-list">
          <input
            type="search"
            placeholder="Search Country..."
            className="search form-control"
            value={search}
            onChange={(e) => {
              let currentValue = e.target?.value?.toUpperCase();
              let filterData = countryListOption?.filter((item) => {
                return (
                  item?.countryName?.toUpperCase()?.includes(currentValue) ||
                  item?.countryCode?.toUpperCase()?.includes(currentValue)
                );
              });
              setList(filterData);
              setSearch(e.target.value);
            }}
          />
          <ul>
            {list?.map((item, index) => (
              <li
                className="list"
                key={index}
                onClick={() => {
                  onChange && onChange(item);
                  setOpenCountryList(false);
                }}
              >
                <img src={item?.countryImage} alt={item?.countryName} />
                <span>{item?.countryName}</span>
              </li>
            ))}
            {isCountryListLoading && <Icons title={"loader"} color="#0a376a" />}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CountryDropdown;
