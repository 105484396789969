import { appActions } from "../../action";

export const loginAction = payload => ({
  type: appActions.LOGIN,
  payload,
});
export const resetPasswordAction = payload => ({
  type: appActions.RESET_PASSWORD,
  payload,
});

export const getUserAction = payload => ({
  type: appActions.GET_USER,
  payload,
});

export const logoutAction = payload => ({
  type: appActions.LOGOUT,
  payload,
});

export const updatePasswordAction = payload => ({
  type: appActions.UPDATE_PASSWORD,
  payload,
});


export const changeCallHippoPermissionAction=payload => ({
  type: appActions.CHANGE_CALL_HIPPO_PERMISSION,
  payload,
});