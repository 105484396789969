import React, { Fragment, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getExpertListOptionAction } from "../../../../services/action/common";
import Buttons from "../../../utils/buttons/Buttons";
import { updateLeadsActionManager } from "../../../../services/action/manager";

const AssignExpert = ({ openLead, onClose }) => {
  const { user } = useSelector((state) => state.authenticationReducer);
  const { expertListOption } = useSelector((state) => state.commonReducer);
  const userType = user?.userType;
  const isManager = userType === "MANAGER";
  const dispatch = useDispatch();
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const getWatch = watch();
  const isInitialized = useRef(false); 
  useEffect(() => {
    if (userType && userType !== "client" && userType !== "expert") {
      dispatch(getExpertListOptionAction({ userType }));
    }
  }, [userType, dispatch]);


  useEffect(() => {
    if (openLead && !isInitialized.current) {
      setValue("expert", openLead?.expert?._id);
      setValue(
        "expertAmount",
        openLead?.expertAmount < 1 ? "" : openLead?.expertAmount
      );
      if (openLead?.clientDeadline) {
        let dates = new Date(openLead?.clientDeadline);
        const formattedDate = dates.toISOString().slice(0, 16);
        setValue("clientDeadline", formattedDate);
      }

      if (openLead?.expertDeadline) {
        let dates = new Date(openLead?.expertDeadline);
        const formattedDate = dates.toISOString().slice(0, 16);
        setValue("expertDeadline", formattedDate);
      }
      isInitialized.current = true; // Mark as initialized
    }
  }, [openLead, setValue]);

  const onSubmit=(data)=>{
    if (data) {
      data._id = openLead?._id;
      data.orderStatus = "ASSIGNED";
      data.expertAmount=data?.expertAmount ? parseInt(data?.expertAmount):""
      dispatch(updateLeadsActionManager({ userType:userType?.toLowerCase(), data,cb:onClose }));
    }
  }

  return (
    <Fragment>
      <form className="form-control">
        <div className="row custom-form generate-lead-form">
          <div className="col-sm-6">
            <label className={errors?.expert && "text-danger"}>
              Expert<span className="text-danger">*</span>
            </label>
            <select
              className="form-control"
              {...register("expert", {
                required: true,
              })}
              disabled={openLead?.expert && !isManager}
            >
              <option value={""}></option>
              
              {expertListOption?.map((item, index) => (
                <option
                  selected={getWatch?.expert === item?.value}
                  key={index}
                  value={item?.value}
                >
                  {item?.label}
                </option>
              ))}
            </select>
          </div>
          <div className="col-sm-6">
            <label className={errors?.expertDeadline && "text-danger"}>
              Expert Deadline<span className="text-danger">*</span>
            </label>
            <input
              {...register("expertDeadline", {
                required: true,
              })}
              disabled={!isManager && openLead?.expertDeadline}
              min={new Date().toISOString().slice(0, 16)}
              type="datetime-local"
              max={
                getWatch?.clientDeadline &&
                new Date(getWatch?.clientDeadline).toISOString().slice(0, 16)
              }
              className="form-control"
            />
          </div>
          <div className="col-sm-6">
            <label className={errors?.expertAmount && "text-danger"}>
              Expert Amount<span className="text-danger">*</span>
            </label>
            <input
              {...register("expertAmount", {
                required: true,
              })}
              type="text"
              disabled={openLead?.expertAmount && !isManager}
              className="form-control"
            />
          </div>
          <div className="col-sm-6">
            <label className={errors?.clientDeadline && "text-danger"}>
              Client Deadline<span className="text-danger">*</span>
            </label>
            <input
              {...register("clientDeadline", {
                required: true,
              })}
              disabled={true}
              min={new Date().toISOString().slice(0, 16)}
              type="datetime-local"
              className="form-control"
            />
          </div>
          <div className="col-sm-12 mt-2 text-center"><Buttons title={"Assign"} onClick={handleSubmit(onSubmit)}/></div>
        </div>
      </form>

    </Fragment>
  );
};

export default AssignExpert;
