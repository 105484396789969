import React from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const CustomDatePicker = ({value,setValue,minDate}) => {
  return (
    <DatePicker minDate={minDate} selected={value} onChange={(date) => setValue(date)} isClearable={true}/>
  );
};
export default CustomDatePicker;