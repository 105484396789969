import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles/styles.scss"
import './index.css';
import { Provider } from "react-redux";
import { store } from "./services/store/index.js"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ChatBox from './components/card/ChatBox.jsx';
import OneToOneChat from './components/live-users/OneToOneChat.jsx';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ChatBox />
    <OneToOneChat />
    <ToastContainer
      hideProgressBar
      position="top-center"
      limit={1}
      newestOnTop={true}
      autoClose={2000}
    />
    <App />

  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
