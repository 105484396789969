// import node module libraries
import { Link } from "react-router-dom";
import { Image, Dropdown, ListGroup } from "react-bootstrap";

import NotificationIcon from "./NotificationIcon";
import NotificationList from "./component/NotificationList";
import { useSelector } from "react-redux";
import Logout from "../../utils/Logout";
import { defaultAvatarImg } from "../../utils/TeamsData";
import { Fragment, useState } from "react";
import CustomModal from "../../utils/modals/CustomModal";
import FormCallHippoPermission from "./component/FormCallHippoPermission";
const QuickMenuDesktop = () => {
  const { notificationList } = useSelector((state) => state.commonReducer);
  const { user } = useSelector((state) => state.authenticationReducer);
  const [changeCallHippoPermission, setCallHippoPermission] = useState(false);



  return (
    <Fragment>
      <CustomModal
        open={changeCallHippoPermission}
        size="md"
        title={"Change Permission"}
        content={()=><FormCallHippoPermission />}
        onClose={() => setCallHippoPermission(false)}
      />
      <ListGroup
        as="ul"
        bsPrefix="navbar-nav"
        className="navbar-right-wrap ms-auto d-flex nav-top-wrap"
      >
        <Dropdown as="li" className="stopevent">
          <Dropdown.Toggle
            as="a"
            bsPrefix=" "
            id="dropdownNotification"
            className="btn btn-light btn-icon rounded-circle indicator indicator-primary text-muted"
          >
            <NotificationIcon count={notificationList?.newCount} />
          </Dropdown.Toggle>
          <Dropdown.Menu
            className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-end py-0"
            aria-labelledby="dropdownNotification"
            align="end"
            show
          >
            <Dropdown.Item className="mt-3" bsPrefix=" " as="div">
              <div className="border-bottom px-3 pt-0 pb-3 d-flex justify-content-between align-items-end">
                <span className="h4 mb-0">Notifications</span>
                <Link href="/" className="text-muted">
                  <span className="align-middle">
                    <i className="fe fe-settings me-1"></i>
                  </span>
                </Link>
              </div>
              <NotificationList />
              {/* <div className="border-top px-3 pt-3 pb-3">
                            <Link href="/dashboard/notification-history" className="text-link fw-semi-bold">
                                See all Notifications
                            </Link>
                        </div> */}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown as="li" className="ms-2">
          <Dropdown.Toggle
            as="a"
            bsPrefix=" "
            className="rounded-circle"
            id="dropdownUser"
          >
            <div className="avatar avatar-md avatar-indicators avatar-online">
              <Image
                alt="avatar"
                src={defaultAvatarImg}
                className="rounded-circle"
              />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu
            className="dropdown-menu dropdown-menu-end "
            align="end"
            aria-labelledby="dropdownUser"
            show
          >
            {user?.name && (
              <Dropdown.Item as="div" className="px-4 pb-0 pt-2" bsPrefix=" ">
                <div className="lh-1 ">
                  <h5 className="mb-1"> {user?.name}</h5>
                  {/* <Link href="#" className="text-inherit fs-6">View my profile</Link> */}
                </div>
                <div className="dropdown-divider mt-3 mb-2"></div>
              </Dropdown.Item>
            )}
           {(user?.userType==="MANAGER" || user?.userType==="ADMIN" || user?.userType==="SUPER_ADMIN" )&& ( <Dropdown.Item eventKey="2" onClick={()=>setCallHippoPermission(true)}>
              <i className="fa fa-phone me-2" aria-hidden="true"></i>Change
              Permission
            </Dropdown.Item>)}
            <Dropdown.Item>
              <i className="fe fe-power me-2"></i>
              <Logout child={"Sign Out"} />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </ListGroup>
    </Fragment>
  );
};
export default QuickMenuDesktop;
