import { appActions } from "../../action";
const initialState = {};

export const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${appActions.UPLOAD_IMAGE}_START`:
      return { ...state, isImageLoading: true };
    case `${appActions.UPLOAD_IMAGE}_SUCCESS`:
      return { ...state, isImageLoading: false };
    case `${appActions.UPLOAD_IMAGE}_FAIL`:
      return { ...state, isImageLoading: false };

    case `${appActions.START_CHAT}_START`:
      return { ...state, isLoading: true };
    case `${appActions.START_CHAT}_SUCCESS`:
      return {
        ...state,
        isLoading: false,
        leadDetail: action.payload.leadDetail,
      };
    case `${appActions.START_CHAT}_FAIL`:
      return { ...state, isLoading: false };

    case appActions.CLOSE_CHAT:
      return { ...state, isLoading: false, leadDetail: undefined };
    case `${appActions.GET_DASHBOARD}_START`:
      return { ...state, isDashboardLoading: true };
    case `${appActions.GET_DASHBOARD}_SUCCESS`:
      return {
        ...state,
        isDashboardLoading: false,
        dashboardData: action.payload,
      };
    case `${appActions.GET_DASHBOARD}_FAIL`:
      return { ...state, isDashboardLoading: false };

    case `${appActions.GET_EXPERT_LIST_OPTION}_SUCCESS`:
      return {
        ...state,
        isLoading: false,
        expertListOption: action.payload?.data,
      };

    case `${appActions.GET_NOTIFICATION_LIST}_SUCCESS`:
      return { ...state, isLoading: false, notificationList: action.payload };

    case appActions.UPDATE_NOTIFICATION:
      let currentCount = (state?.notificationList?.newCount || 0) + 1;
      let currentList = state?.notificationList?.data || [];
      currentList = [action?.payload, ...currentList];
      return {
        ...state,
        notificationList: {
          newCount: currentCount,
          data: currentList,
        },
      };

    case `${appActions.GET_COUNTRY_LIST}_START`:
      return { ...state, isCountryListLoading: true };
    case `${appActions.GET_COUNTRY_LIST}_SUCCESS`:
      const extractedData = action?.payload?.map((country) => {
        const countryCode =
          (country.idd?.root || "") + (country.idd?.suffixes?.[0] || "");
        return {
          countryCode: countryCode || "N/A",
          countryName: country.name.common,
          officialName: country.name.official,
          countryImage: country.flags.svg,
        };
      });
      return {
        ...state,
        isCountryListLoading: false,
        countryListOption: extractedData,
      };
    case `${appActions.GET_COUNTRY_LIST}_FAIL`:
      return { ...state, isCountryListLoading: false };

    case `${appActions.UPLOAD_AUDIO}_START`:
      return { ...state, isAudioLoading: true };
    case `${appActions.UPLOAD_AUDIO}_SUCCESS`:
      return { ...state, isAudioLoading: false };
    case `${appActions.UPLOAD_AUDIO}_FAIL`:
      return { ...state, isAudioLoading: false };

    case `${appActions.GET_OPERATOR_MANAGER_LIST_ACTION}_START`:
      return { ...state, isOperListOption: true };
    case `${appActions.GET_OPERATOR_MANAGER_LIST_ACTION}_SUCCESS`:
      return {
        ...state,
        isOperListOption: false,
        operListOption: action?.payload?.data,
      };
    case `${appActions.GET_OPERATOR_MANAGER_LIST_ACTION}_FAIL`:
      return { ...state, isOperListOption: false };

    case `${appActions.REQUEST_FOR_ASSIGN_LEAD}_START`:
      return { ...state, isAssignRquest: true };
    case `${appActions.REQUEST_FOR_ASSIGN_LEAD}_SUCCESS`:
      return { ...state, isAssignRquest: false };
    case `${appActions.REQUEST_FOR_ASSIGN_LEAD}_FAIL`:
      return { ...state, isAssignRquest: false };

    case `${appActions.GET_ALL_LIVE_USER_LIST}_START`:
      return { ...state, isLiveUserloading: true };
    case `${appActions.GET_ALL_LIVE_USER_LIST}_SUCCESS`:
      return {
        ...state,
        isLiveUserloading: false,
        allLiveUser: action?.payload,
      };
    case `${appActions.GET_ALL_LIVE_USER_LIST}_FAIL`:
      return { ...state, isLiveUserloading: false };

    case `${appActions.GET_OPEN_USER_CHAT_LIST}_START`:
      return { ...state, isLoadOpenChat: true };
    case `${appActions.GET_OPEN_USER_CHAT_LIST}_SUCCESS`:
      return {
        ...state,
        isLoadOpenChat: false,
        openUserChatList: action?.payload?.data,
      };
    case `${appActions.GET_OPEN_USER_CHAT_LIST}_FAIL`:
      return { ...state, isLoadOpenChat: false };

    case `${appActions.GET_OPEN_NOTIFICATION}_START`:
      return { ...state, isNotificationLoad: true };
    case `${appActions.GET_OPEN_NOTIFICATION}_SUCCESS`:
      return {
        ...state,
        isNotificationLoad: false,
        openNoticationList: action?.payload?.data,
      };
    case `${appActions.GET_OPEN_NOTIFICATION}_FAIL`:
      return { ...state, isNotificationLoad: false };


    case appActions.START_OPEN_CHAT:
      return {
        ...state,
        currentOpenChatUser: action?.payload,
      };

      case appActions.CLOSE_OPEN_CHAT:
        return {
          ...state,
          currentOpenChatUser: false,
        };


        case appActions.SET_NOTIFICATION_PERMISSION:
          return { ...state, isShowNotificationPopup:action?.payload?.isShown };
          

    default:
      return { ...state, isLoading: false };
  }
};
