import { Fragment, useRef, useState } from "react";
import CustomModal from "../../../utils/modals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { uploadImageAction } from "../../../../services/action/common";
import { FormGroup } from "@mui/material";
import Icons from "../../../utils/Icons";
import ImageUrlList from "../../../manager/leads/components/ImageUrlList";
import Buttons from "../../../utils/buttons/Buttons";
import EditorText from "../../../utils/text-editor/EditorText";
import { removeUnnecessaryTags } from "../../../utils/functions/functions";

const MarkQuery = ({ markQuery, setMarkQuery,sendMessage }) => {
  const dispatch = useDispatch();
  const [messageContent,setMesageContent]=useState("")
  const fileRef = useRef();
  const [imageUrls, setImageUrls] = useState([]);

  const { isImageLoading } = useSelector((state) => state.commonReducer);

  const uploadImage = (e) => {
    const callBackImage = (res) => {
      let tempData = [...imageUrls];
      setImageUrls([...tempData, ...res?.imageUrls]);
    };
    let formData = new FormData();
    for (let i = 0; i < e.target.files.length; i++) {
      formData.append("images", e.target.files[i]);
    }
    dispatch(uploadImageAction({ data: formData, cb: callBackImage }));
    e.target.value = null;
  };

  const onSubmit = () => {
    let messageFile = "";
    imageUrls?.map((item) => {
      messageFile =
        messageFile +
        `<a target='_blank' href=${item}><i class="fa fa-file" aria-hidden="true"></i></a>`;
    });

    const message = `<div class="mark-query-chat-box">${removeUnnecessaryTags(messageContent)} <p class="file-list">${messageFile} </p></div>`;
    sendMessage(message, false, ["EXPERT", "OPERATOR", "MANAGER"]);
    setMarkQuery({})
  };

  const Content = () => {
    return (
      <FormGroup>
        <EditorText  setValue={setMesageContent} value={messageContent}/>
        <div
          className="delivery-box-input-file mt-2"
          onClick={() => fileRef.current?.click()}
        >
          <p className="m-0 text-center">Upload File</p>
          {isImageLoading && (
            <p className="m-0 text-center">
              <Icons title={"loader"} color="#0a376a" />
            </p>
          )}
        </div>
        <input
          type="file"
          onChange={uploadImage}
          className="display-none"
          ref={fileRef}
          multiple={true}
        />
        {!!imageUrls?.length && (
          <ImageUrlList
            items={imageUrls}
            onDelete={(image) => {
              let filterDocument = imageUrls?.filter((item) => item !== image);
              setImageUrls(filterDocument);
            }}
          />
        )}
        <Buttons title={"Submit"} onClick={onSubmit} />
      </FormGroup>
    );
  };
  return (
    <Fragment>
      <CustomModal
        open={markQuery?.modal}
        title={"Mark Query"}
        zIndex={10000}
        size="md"
        content={Content}
        onClose={() => setMarkQuery({})}
      />
    </Fragment>
  );
};

export default MarkQuery;
