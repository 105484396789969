import React from "react";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

const SelectBox = ({
  creatable,
  options,
  onChange,
  value,
  closeMenuOnSelect,
  isMulti,
  className,
  disabled
}) => {
  if (creatable) {
    return (
      <CreatableSelect
        options={options}
        onChange={onChange}
        value={value}
        closeMenuOnSelect={closeMenuOnSelect}
        className={className}
        isDisabled={disabled}
        
      />
    );
  }
  return (
    <Select
      options={options}
      onChange={onChange}
      isMulti={isMulti}
      isDisabled={disabled}
      value={value}
      className={className}
      closeMenuOnSelect={closeMenuOnSelect}
    />
  );
};
export default SelectBox;
