import React, { useEffect } from "react";
import OperatorListComponent from "../../components/manager/users/operators/OperatorListComponent";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsersAction } from "../../services/action/manager";
import DashboardLayout from "../../components/layouts/DashboardLayout";

const OperatorListSA = () => {
  const dispatch = useDispatch();
  const { userList } = useSelector((state) => state.managerReducer);

  const getOperator = ({ currentPage, isEmptylist = true, keyword = "" }) => {
    dispatch(
      getAllUsersAction({
        userType:"super_admin",
        data: { userType: "OPERATOR", keyword, page: currentPage, isEmptylist },
      })
    );
  };
  useEffect(() => {
    getOperator({ currentPage: 1 });
  }, []);
  return (
    <DashboardLayout
      onSearch={(e) =>
        getOperator({ currentPage: 1, isEmptylist: false, keyword: e })
      }
    >
      <OperatorListComponent TeamsData={userList} getOperator={getOperator} />
    </DashboardLayout>
  );
};

export default OperatorListSA;
