export const sourceList = [
    { label: "", value: "" },
  { label: "WhatsApp UK", value: "WhatsApp UK" },
  { label: "WhatsApp AUS", value: "WhatsApp AUS" },
  { label: "WhatsApp USA", value: "WhatsApp USA" },
  { label: "website", value: "website" },
  { label: "Collect Chat", value: "Collect Chat" },
  { label: "Direct Call", value: "Direct Call" },
  { label: "Meta", value: "Meta" },
  { label: "Aisensy", value: "Aisensy" },
  { label: "Google Form", value: "Google Form" },
  { label: "Referral", value: "Referral" },
  { label: "LinkedIn Pulse", value: "LinkedIn Pulse" },
  { label: "Other", value: "Other" },
];


export const userListOption=[
    {label:"OPERATOR", value:"OPERATOR"},
    {label:"EXPERT", value:"EXPERT"},
    {label:"MANAGER", value:"MANAGER"},
    {label:"CLIENT", value:"CLIENT"},
]

export const covertIntoLabelAndObject=(data)=>{
  return data?.map((item)=>({label:item,value:item}))
}

export const subjectList = [
  {
    label: "Mathematics",
    options: [
      { value: "Algebra", label: "Algebra" },
      { value: "Calculus", label: "Calculus" },
      { value: "Statistics", label: "Statistics" },
      { value: "Geometry", label: "Geometry" },
      { value: "Trigonometry", label: "Trigonometry" },
    ],
  },
  {
    label: "Science",
    options: [
      { value: "Physics", label: "Physics" },
      { value: "Chemistry", label: "Chemistry" },
      { value: "Biology", label: "Biology" },
      { value: "Environmental Science", label: "Environmental Science" },
      { value: "Earth Science", label: "Earth Science" },
    ],
  },
  {
    label: "Engineering",
    options: [
      { value: "Mechanical Engineering", label: "Mechanical Engineering" },
      { value: "Electrical Engineering", label: "Electrical Engineering" },
      { value: "Civil Engineering", label: "Civil Engineering" },
      { value: "Computer Engineering", label: "Computer Engineering" },
      { value: "Chemical Engineering", label: "Chemical Engineering" },
    ],
  },
  {
    label: "Computer Science",
    options: [
      { value: "Programming", label: "Programming (Python, Java, C++, etc.)" },
      { value: "Data Structures and Algorithms", label: "Data Structures and Algorithms" },
      { value: "Database Management", label: "Database Management" },
      { value: "Artificial Intelligence", label: "Artificial Intelligence" },
      { value: "Machine Learning", label: "Machine Learning" },
    ],
  },
  {
    label: "Business and Management",
    options: [
      { value: "Marketing", label: "Marketing" },
      { value: "Human Resource Management", label: "Human Resource Management" },
      { value: "Finance", label: "Finance" },
      { value: "Operations Management", label: "Operations Management" },
      { value: "Project Management", label: "Project Management" },
    ],
  },
  {
    label: "Economics",
    options: [
      { value: "Microeconomics", label: "Microeconomics" },
      { value: "Macroeconomics", label: "Macroeconomics" },
      { value: "Econometrics", label: "Econometrics" },
      { value: "International Economics", label: "International Economics" },
      { value: "Public Economics", label: "Public Economics" },
    ],
  },
  {
    label: "Accounting",
    options: [
      { value: "Financial Accounting", label: "Financial Accounting" },
      { value: "Managerial Accounting", label: "Managerial Accounting" },
      { value: "Auditing", label: "Auditing" },
      { value: "Taxation", label: "Taxation" },
      { value: "Corporate Accounting", label: "Corporate Accounting" },
    ],
  },
  {
    label: "Law",
    options: [
      { value: "Criminal Law", label: "Criminal Law" },
      { value: "Contract Law", label: "Contract Law" },
      { value: "International Law", label: "International Law" },
      { value: "Constitutional Law", label: "Constitutional Law" },
      { value: "Corporate Law", label: "Corporate Law" },
    ],
  },
  {
    label: "Nursing and Healthcare",
    options: [
      { value: "Nursing Practice", label: "Nursing Practice" },
      { value: "Medical-Surgical Nursing", label: "Medical-Surgical Nursing" },
      { value: "Pediatric Nursing", label: "Pediatric Nursing" },
      { value: "Public Health", label: "Public Health" },
      { value: "Pharmacology", label: "Pharmacology" },
    ],
  },
  {
    label: "Social Sciences",
    options: [
      { value: "Psychology", label: "Psychology" },
      { value: "Sociology", label: "Sociology" },
      { value: "Anthropology", label: "Anthropology" },
      { value: "Political Science", label: "Political Science" },
      { value: "History", label: "History" },
    ],
  },
  {
    label: "Humanities",
    options: [
      { value: "Literature", label: "Literature" },
      { value: "Philosophy", label: "Philosophy" },
      { value: "Art History", label: "Art History" },
      { value: "Cultural Studies", label: "Cultural Studies" },
      { value: "Religious Studies", label: "Religious Studies" },
    ],
  },
  {
    label: "Education",
    options: [
      { value: "Educational Psychology", label: "Educational Psychology" },
      { value: "Curriculum Development", label: "Curriculum Development" },
      { value: "Classroom Management", label: "Classroom Management" },
      { value: "Special Education", label: "Special Education" },
      { value: "Early Childhood Education", label: "Early Childhood Education" },
    ],
  },
  {
    label: "Environmental Studies",
    options: [
      { value: "Sustainable Development", label: "Sustainable Development" },
      { value: "Environmental Policy", label: "Environmental Policy" },
      { value: "Conservation Biology", label: "Conservation Biology" },
      { value: "Climate Change", label: "Climate Change" },
      { value: "Renewable Energy", label: "Renewable Energy" },
    ],
  },
  {
    label: "Languages",
    options: [
      { value: "English", label: "English" },
      { value: "Spanish", label: "Spanish" },
      { value: "French", label: "French" },
      { value: "German", label: "German" },
      { value: "Chinese", label: "Chinese" },
    ],
  },
  {
    label: "Media and Communication",
    options: [
      { value: "Journalism", label: "Journalism" },
      { value: "Public Relations", label: "Public Relations" },
      { value: "Media Studies", label: "Media Studies" },
      { value: "Digital Marketing", label: "Digital Marketing" },
      { value: "Film Studies", label: "Film Studies" },
    ],
  },
  {
    label: "Fine Arts",
    options: [
      { value: "Visual Arts", label: "Visual Arts" },
      { value: "Music", label: "Music" },
      { value: "Theater", label: "Theater" },
      { value: "Dance", label: "Dance" },
      { value: "Graphic Design", label: "Graphic Design" },
    ],
  },
  {
    label: "Architecture",
    options: [
      { value: "Architectural Design", label: "Architectural Design" },
      { value: "Urban Planning", label: "Urban Planning" },
      { value: "Landscape Architecture", label: "Landscape Architecture" },
      { value: "Interior Design", label: "Interior Design" },
      { value: "Building Technology", label: "Building Technology" },
    ],
  },
  {
    label: "Health Sciences",
    options: [
      { value: "Public Health", label: "Public Health" },
      { value: "Nutrition", label: "Nutrition" },
      { value: "Physical Therapy", label: "Physical Therapy" },
      { value: "Occupational Therapy", label: "Occupational Therapy" },
      { value: "Health Informatics", label: "Health Informatics" },
    ],
  },
  {
    label: "Sports Science",
    options: [
      { value: "Sports Management", label: "Sports Management" },
      { value: "Exercise Physiology", label: "Exercise Physiology" },
      { value: "Sports Psychology", label: "Sports Psychology" },
      { value: "Coaching", label: "Coaching" },
      { value: "Kinesiology", label: "Kinesiology" },
    ],
  },
  {
    label: "Human Resources",
    options: [
      { value: "Talent Management", label: "Talent Management" },
      { value: "Organizational Behavior", label: "Organizational Behavior" },
      { value: "Labor Relations", label: "Labor Relations" },
      { value: "Compensation and Benefits", label: "Compensation and Benefits" },
      { value: "Recruitment and Selection", label: "Recruitment and Selection" },
    ],
  },
];

// You can now pass this `options` array to the `SelectBox` component.
