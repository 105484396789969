import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { returnDateWithTime } from "../../utils/functions/functions";

const LeadDetailCard = ({ leadDetails }) => {
  const { user } = useSelector((state) => state.authenticationReducer);
  const isManagerOperatorAdminSuperAdmin =
    user?.userType === "MANAGER" ||
    user?.userType === "OPERATOR" ||
    user?.userType === "ADMIN" ||
    user?.userType === "SUPER_ADMIN";
  const ExpertContent = () => {
    return(    <Fragment>
      <div className="lead-details-chat">
        <ul>
          <li>
            <p>
              <span className="title">Expert Deadline : </span>
              <span className="value">{returnDateWithTime(leadDetails?.expertDeadline)} </span>
            </p>
          </li>
          <li>
            <p>
              <span className="title">Expert Payment : </span>
              <span className="value">
                {leadDetails?.expertAmount} {"INR"}
              </span>
            </p>
          </li>
          <li>
            <p>
              <span className="title">Subject : </span>
              <span className="value">
                {leadDetails?.subject}
              </span>
            </p>
          </li>
          <li>
            <p>
              <span className="title">Total Word Count : </span>
              <span className="value">{leadDetails?.wordCount}</span>
            </p>
          </li>
          <li>
            <p>
              <span className="title">Referencing Style : </span>
              <span className="value">{leadDetails?.referenceType} </span>
            </p>
          </li>
        </ul>
      </div>
    </Fragment>
    )
  };
  const ManagerAdminOperatorContent = () => {
    return (
      <Fragment>
        <div className="lead-details-chat">
          <ul>
            <li>
              <p>
                <span className="title">Order Status : </span>
                <span className="value">{leadDetails?.orderStatus} </span>
              </p>
            </li>
            <li>
              <p>
                <span className="title">Total Payment : </span>
                <span className="value">
                  {leadDetails?.totalPayment} {leadDetails?.currancy}{" "}
                </span>
              </p>
            </li>
            <li>
              <p>
                <span className="title">Balance Payment : </span>
                <span className="value">
                  {leadDetails?.totalPayment - leadDetails?.paymentRecieved}{" "}
                  {leadDetails?.currancy}{" "}
                </span>
              </p>
            </li>
            <li>
              <p>
                <span className="title">Client Deadline : </span>
                <span className="value">
                  {returnDateWithTime(leadDetails?.clientDeadline)}{" "}
                </span>
              </p>
            </li>
            <li>
              <p>
                <span className="title">Total Word Count : </span>
                <span className="value">{leadDetails?.wordCount}</span>
              </p>
            </li>
            <li>
              <p>
                <span className="title">Subject : </span>
                <span className="value">{leadDetails?.subject} </span>
              </p>
            </li>
            <li>
              <p>
                <span className="title">Referencing Style : </span>
                <span className="value">{leadDetails?.referenceType} </span>
              </p>
            </li>
          </ul>
        </div>
      </Fragment>
    );
  };
  return (
    isManagerOperatorAdminSuperAdmin?<ManagerAdminOperatorContent />:<ExpertContent />
  )
};

export default LeadDetailCard;
