import React from 'react'
import Icons from '../../utils/Icons'

const InitialChatSelection = ({onSelect,setShowInitialMessage}) => {
  return (
    <div className='subject-selection'>
         <p className='first-heading'>Do you <b>Need Help</b>? <span style={{marginTop:"-10px",display:"inline-block",marginLeft:"35px"}} onClick={()=>setShowInitialMessage(false)}> <Icons title={"cross"} color="black" /></span></p>
         <ul>
            <li onClick={()=>onSelect("Assignment Help",true)}>Assignment Help</li>
            <li onClick={()=>onSelect("Dissertation Help",true)}>Dissertation Help</li>
            <li onClick={()=>onSelect("Homework Help",true)}>Homework Help</li>
            <li onClick={()=>onSelect("Coursework Help",true)}>Coursework Help</li>
            <li onClick={()=>onSelect("Assessment Help",true)}>Assessment Help</li>
            <li onClick={()=>onSelect("Thesis Help",true)}>Thesis Help</li>
            <li onClick={()=>onSelect("Essay Help",true)}>Essay Help</li>
            <li onClick={()=>onSelect("Research Proposal",true)}>Research Proposal</li>
            <li onClick={()=>onSelect("Assignment Help",true)}>Assignment Help</li>
            <li onClick={()=>onSelect("Exam Help",true)}>Exam Help</li><br/>
            <li onClick={()=>onSelect("Sample",true)}>Sample</li>
            <li onClick={()=>onSelect("Other",true)}>Other</li>
         </ul>
        
    </div>
  )
}

export default InitialChatSelection