import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

export default function Paginations({pagination,onChange}) {
  return (
    <Stack spacing={2}
    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    > 
       {pagination?.total<1 ? "No Record Found..":
      <Pagination page={pagination.page} onChange={(_,page)=>onChange(page)} count={pagination?.totalPages}  variant="outlined" color="primary" />}
    </Stack>
  );
}
